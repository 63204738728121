import * as React from "react"
import { TAppData } from "../app/AppContext"
import { TData } from "../app/interface"
import { getCalendarEventsModule, getModuleChildren, getTodoModule } from "../app/utils"
import { ScheduledActivity } from "../components/activity_generator/ScheduledActivity"
import { CalendarData } from "../components/calendar/CalendarData"
import { Activity } from "../data/activity/Activity"
import { ActivityStatus, modules } from "../utils/constants"
import { getToday, addDaysStringDate } from "../utils/TimeUtils"
import { Layout } from "./Layout"
import { IModuleRelationsProps, ModuleRelations } from "./modules/ActivityTableModule/ModuleRelations"
import { BaseModule, IBaseModule } from "./modules/BaseModule"
import { BoardModule, IBoardModuleProps } from "./modules/BoardModule/BoardModule"
import { CalendarEventsModule, ICalendarEventsModule } from "./modules/CalendarEventsModule/CalendarEventsModule"
import { ICardModuleProps, CardModule } from "./modules/CardModule/CardModule"
import { GridItem } from "./modules/ReactGridModule/GridItem"
import { IReactGridModuleProps, ReactGridModule } from "./modules/ReactGridModule/ReactGridModule"
import { IScheduledActivityCardModuleProps, ScheduledActivityCardModule } from "./modules/ScheduledActivityCardModule/ScheduledActivityCardModule"
import { TodoBoardModule } from "./modules/TodoBoardModule/TodoBoardModule"
import { ActivityTableModule, IActivityTableModuleProps } from './modules/ActivityTableModule/ActivityTableModule';
import { generateId } from "../utils/Utils"
import ModulePicker from "../components/pickers/module_picker/ModulePicker"
import { Diary } from "./modules/CalendarEventsModule/Diary"

const moduleCreator = async (params, dataContext, appContext): Promise<BaseModule> => {
    if (params.type === modules.CARD) {
        return await createCardModule({ user: appContext.user, id: generateId(), type: modules.CARD, name: params.name, onDelete: () => { } }, appContext, dataContext)
    }
    else if (params.type === modules.SCHEDULED_ACTIVITY_CARD) {
        return await createScheduledActivityCardModule({ user: appContext.user, id: generateId(), type: modules.SCHEDULED_ACTIVITY_CARD, name: params.name, onDelete: () => { } }, appContext, dataContext)
    }
    else if (params.type === modules.BOARDS) {
        return await createBoardModule({ user: appContext.user, id: generateId(), type: modules.BOARDS, name: params.name, onDelete: () => { } }, appContext, dataContext)
    }
}

const renderModulePicker = (moduleId: string, dataContext: TData, appContext: TAppData) => {
    return <ModulePicker
        modules={[]}
        onPick={async (selectedModule: BaseModule) => {
            const self = await dataContext.modules.getModule(moduleId, dataContext, appContext, false);
            if (self instanceof ModuleRelations && selectedModule instanceof ModuleRelations) {
                self.moveToAnotherModule(selectedModule);
                self.closeModal();
            }
            else{
                console.error("module wasn't movable")
            }
        }}
    />
}

export const createCardModule = async (baseProps: IBaseModule, appContext: TAppData, dataContext: TData) => {
    let module: BaseModule;
    const moduleOptions = [{
        name: modules.SCHEDULED_ACTIVITY_CARD,
        onClick: ScheduledActivityCardModule.getCreationDialog(baseProps.user, async (newModule: ScheduledActivityCardModule) => {
            newModule.parentModule = this
        }),
        testId: 'create-scheduled',
        title: 'Skapa en ny uppgift'
    },
    {
        name: modules.BOARDS,
        onClick: BoardModule.getCreationDialog(baseProps.user, async (newModule: BoardModule) => {
            newModule.parentModule = this
            // this.addChildModule(newModule, this);
        }),
        testId: 'create-board',
        title: 'Skapa en ny lista'
    }]
    const childModules = await getModuleChildren(baseProps, appContext, dataContext)
    const cardProps: ICardModuleProps = {
        modulePicker: renderModulePicker(baseProps.id, dataContext, appContext),
        containerOnDragStop: (baseModule: BaseModule) => { baseModule.updateFirebase(); },
        containerOnResizeStop: (self: BaseModule) => {
            self.updateFirebase();
        },
        onCheckboxChange: (module: BaseModule) => {
            module.updateFirebase();
        },
        moduleOptions
    }
    const moduleRelations: IModuleRelationsProps = {
        newChildModule: async (params) => {
            return await moduleCreator(params, dataContext, appContext)
        },
        onCreateChildModule: (createdModule: ModuleRelations, yourSelf: ModuleRelations) => {
            createdModule.updateFirebase();
            yourSelf.updateFirebase();
        },
        onRemoveChildModule: (removedModule?: ModuleRelations, yourself?: ModuleRelations) => {
            removedModule.parentModule.layout.updateFirebaseData(appContext.user, removedModule.parentModule.id, appContext.currentBreakpoint);
            removedModule.deleteFromFirebase();
        },
        parentModule: null,
        childModules,
        layout: (baseProps as any).layout ? new Layout((baseProps as any).layout) : null,
    }
    module = new CardModule(
        { ...baseProps, onDelete: (self: BaseModule) => { console.log("Delete", self) } },
        cardProps,
        moduleRelations
    );
    childModules.forEach((m: any) => m.parentModule = module);
    return module;
}

export const createScheduledActivityCardModule = async (baseProps: IBaseModule, appContext: TAppData, dataContext: TData) => {
    let module;
    const childModules = await getModuleChildren(baseProps, appContext, dataContext)
    const scheduledActivity = await dataContext.scheduledActivities.getScheduledActivity(baseProps.id)
    // const allModules = await dataContext.modules.getAllModules(dataContext, appContext, true);

    const cardModuleProps: ICardModuleProps = {
        containerOnDragStop: (baseModule: BaseModule) => { baseModule.updateFirebase(); },
        containerOnResizeStop: (self: BaseModule) => {
            self.updateFirebase();
        },
        checkbox: (baseProps as any).checkbox,
        checkboxChecked: (baseProps as any).checkboxChecked,
        modulePicker: renderModulePicker(baseProps.id, dataContext, appContext),
        onCheckboxChange: async (module: CardModule) => {
            module.updateFirebase();
            if (module instanceof ScheduledActivityCardModule) {
                const newScheduledActivity: ScheduledActivity = module.scheduledActivity.finish();
                dataContext.scheduledActivities.updateScheduledActivity(module.scheduledActivity, dataContext);
                module.scheduledActivity.updateFirebase(appContext.user);
                if (newScheduledActivity) {
                    const newModule: ScheduledActivityCardModule = await createScheduledActivityCardModule(
                        {
                            user: appContext.user,
                            id: newScheduledActivity.id,
                            type: modules.SCHEDULED_ACTIVITY_CARD,
                            name: module.name,
                            onDelete: () => { }
                        },
                        appContext,
                        dataContext
                    )
                    newModule.scheduledActivity = newScheduledActivity;
                    dataContext.modules.addModule(newModule, true);
                    dataContext.scheduledActivities.setScheduledActivity(newScheduledActivity);
                    dataContext.calendarData.addScheduleToDate(newScheduledActivity, newScheduledActivity.getStartTimeDate(), dataContext, true, true);

                    newModule.updateFirebase();
                    newScheduledActivity.updateFirebase(appContext.user);
                }

            }
            const todoBoardModule = await getTodoModule(dataContext, appContext);
            todoBoardModule.hideCheckedModules()
        }
    }
    const scheduledActivityCardModuleProps: IScheduledActivityCardModuleProps = {
        scheduledActivity,
        onUpdateScheduledActivity: async (updatedScheduledActivity: ScheduledActivityCardModule, oldDate?: string) => {
            console.log(updatedScheduledActivity.name, updatedScheduledActivity.scheduledActivity.getStartTimeDate());
            const newDate = updatedScheduledActivity.scheduledActivity.getStartTimeDate();
            dataContext.calendarData.updateScheduledActivity(updatedScheduledActivity.scheduledActivity, oldDate, newDate, dataContext);
            dataContext.scheduledActivities.updateScheduledActivity(updatedScheduledActivity.scheduledActivity, dataContext);
            updatedScheduledActivity.scheduledActivity.updateFirebase(appContext.user);
            updatedScheduledActivity.updateFirebase();

            const calendarEventsModule = await getCalendarEventsModule(dataContext, appContext);
            calendarEventsModule.onScheduledActivityUpdate(updatedScheduledActivity, oldDate);
        }
    }

    const relationProps: IModuleRelationsProps = {
        onCreateChildModule: (createdModule: ModuleRelations, yourSelf: ModuleRelations) => {
            if ((createdModule as any).scheduledActivity) {
                dataContext.scheduledActivities.setScheduledActivity((createdModule as any).scheduledActivity);
                (createdModule as any).scheduledActivity.updateFirebase(appContext.user)
                dataContext.modules.addModule(createdModule, true);
            }
            createdModule.updateFirebase();
            yourSelf.updateFirebase();
        },
        onRemoveChildModule: (removedModule?: ModuleRelations, yourself?: ModuleRelations) => {
            removedModule.parentModule.layout.updateFirebaseData(appContext.user, removedModule.parentModule.id, appContext.currentBreakpoint);
            removedModule.deleteFromFirebase();
        },
        newChildModule: async (params) => await createScheduledActivityCardModule({ user: appContext.user, id: generateId(), type: modules.SCHEDULED_ACTIVITY_CARD, name: params.name, onDelete: () => { } }, appContext, dataContext),
        parentModule: null,
        childModules: childModules,
        layout: (baseProps as any).layout ? new Layout((baseProps as any).layout) : null,
    }

    module = new ScheduledActivityCardModule(
        baseProps,
        cardModuleProps,
        scheduledActivityCardModuleProps,
        relationProps
    )
    module.childModules.forEach(m => { m.parentModule = module });
    return module;
}

export const createCalendarEventsModule = async (baseProps: IBaseModule, appContext: TAppData, dataContext: TData) => {
    let module: CalendarEventsModule;
    const startDate = getToday()
    const endDate = addDaysStringDate(startDate, 10)
    const dates = await dataContext.calendarData.getDates(startDate, endDate, true, false, dataContext);
    const scheduledActivities: ScheduledActivity[] = await dataContext.calendarData.getScheduledActivitiesInTimespan(startDate, endDate, dataContext);
    const scheduledActivityCardModules: ScheduledActivityCardModule[] = await dataContext.modules.getModules(scheduledActivities.map(s => s.id), dataContext, appContext, true) as ScheduledActivityCardModule[];
    const calendarEventsProps: ICalendarEventsModule = {
        dates,
        startDate,
        endDate,
        activities: dataContext.activities.activities,
        updateCalendarData: (calendarData: CalendarData) => {
            calendarData.updateFirebase(appContext.user)
        },
        onDeleteScheduledActivity: async (scheduledActivity: ScheduledActivityCardModule, calendarData: CalendarData) => {

            dataContext.calendarData.deleteScheduledActivity(calendarData.date, scheduledActivity.id, dataContext);
            dataContext.scheduledActivities.deleteScheduledActivity(scheduledActivity.scheduledActivity);
            scheduledActivity.scheduledActivity.deleteFirebase(appContext.user);

            if (scheduledActivity.parentModule instanceof CalendarEventsModule) {
                scheduledActivity.parentModule.childModules = scheduledActivity.parentModule.childModules.filter(m => m.id !== scheduledActivity.id);
            }

            calendarData.updateFirebase(appContext.user);

        },
        onTemporaryScheduledActivityCreated: (newTemporaryScheduledActivity: ScheduledActivityCardModule, calendarData: CalendarData) => {
            // newTemporaryScheduledActivity.updateFirebase();
            // newTemporaryScheduledActivity.scheduledActivity.updateFirebase(appContext.user);
            // calendarData.updateFirebase(appContext.user);
        },
        onScheduledActivityUpdate: async (updatedScheduledActivity: ScheduledActivityCardModule, oldDate?: string,) => {
            module.updateScheduledActivity(updatedScheduledActivity, oldDate);
            updatedScheduledActivity.closeModal();

        },
        onScheduledActivityClick: (activity: Activity) => { console.log("Scheduled Activity Click", activity) },
        onFinishScheduledActivity: async (scheduledActivity: ScheduledActivityCardModule) => {
            dataContext.scheduledActivities.updateScheduledActivity(scheduledActivity.scheduledActivity, dataContext);
            scheduledActivity.scheduledActivity.updateFirebase(appContext.user);
            // if (newScheduledActivity) {
            //     const calendarData: CalendarData = await dataContext.calendarData.getCalendarDate(newScheduledActivity.scheduledActivity.getStartTimeDate(), true, true, dataContext);
            //     calendarData.schedule.push(newScheduledActivity.scheduledActivity);
            //     newScheduledActivity.scheduledActivity.addScheduledActivity(dataContext, calendarData, appContext.user);
            // }
        },
        diary: <Diary />
    }

    const relationProps: IModuleRelationsProps = {
        newChildModule: async (params) => {
            const newModule = await createScheduledActivityCardModule({ user: appContext.user, id: generateId(), type: modules.SCHEDULED_ACTIVITY_CARD, name: params.name, onDelete: () => { } }, appContext, dataContext)
            const newScheduledActivity = new ScheduledActivity({
                id: newModule.id,
                content: params.name,
                status: ActivityStatus.NOT_STARTED,
                activityId: params.activityId,
                interval: params.interval,
                startTime: params.startTime
            });
            newModule.scheduledActivity = newScheduledActivity;
            return newModule;
        },
        onCreateChildModule: (createdModule: ModuleRelations, yourSelf: ModuleRelations, params) => {
            dataContext.scheduledActivities.setScheduledActivity((createdModule as ScheduledActivityCardModule).scheduledActivity);
            dataContext.modules.addModule(createdModule, true);
            createdModule.updateFirebase();

            if (params?.date) {
                dataContext.calendarData.addScheduleToDate((createdModule as ScheduledActivityCardModule).scheduledActivity, params.date, dataContext, true, true);
            }
        },
        onRemoveChildModule: (removedModule?: ModuleRelations, yourself?: ModuleRelations) => {
            removedModule.deleteFromFirebase();
            if (yourself instanceof CalendarEventsModule) {
                // const calendarData = yourself.dates.find(c => c.schedule.includes(removedModule.id))
                const calendarData = yourself.dates.find(c => c.schedule.some(m => m.id === removedModule.id));
                if (calendarData) {
                    // calendarData.schedule = calendarData.schedule.filter(s => s !== removedModule.id);
                    calendarData.removeScheduledActivity((removedModule as ScheduledActivityCardModule).scheduledActivity);
                    calendarData.updateFirebase(appContext.user);
                }
            }
        },

        parentModule: null,
        childModules: scheduledActivityCardModules,
        layout: (baseProps as any).layout ? new Layout((baseProps as any).layout) : null,
    }
    module = new CalendarEventsModule(
        baseProps,
        relationProps,
        calendarEventsProps,
    )
    scheduledActivityCardModules.forEach(m => { m.parentModule = module });
    return module;
}

export const createGridLayoutModule = async (baseProps: IBaseModule, appContext: TAppData, dataContext: TData) => {
    let module;
    const relationProps: IModuleRelationsProps = {
        newChildModule: async (params) => {
            return await moduleCreator(params, dataContext, appContext)
        },
        parentModule: null,
        childModules: [],
        layout: (baseProps as any).layout || null,
        onCreateChildModule: (createdModule: BaseModule, yourSelf: BaseModule) => {
            createdModule.updateFirebase();
            yourSelf.updateFirebase();
        },
        onRemoveChildModule: (removedModule?: ModuleRelations, parent?: ModuleRelations) => {
            removedModule.deleteFromFirebase();
            parent.forceRender();
            parent.updateFirebase();
        },
    }
    const reactGridModuleProps: IReactGridModuleProps = {
        onDragStart: (newGrid: GridItem) => { /* console.log("Drag Start", newGrid) */ },
        onDragStop: (reactGridModule: ReactGridModule) => {
            reactGridModule.layout.updateFirebaseData(appContext.user, reactGridModule.id, reactGridModule.currentBreakpoint);
            // dataContext.modules.getModule(baseProps.id, dataContext, appContext.user, false).then((module: BaseModule) => {
            //     newGrid.forEach((l: GridItem) => {
            //         (module as ReactGridModule).layout.updateGridItem(l);
            //         writeData(`${getModulesPath(appContext.user, (module as ReactGridModule).layout.id)}/layout/sm/${l.i}`, l)
            //         writeData(`${getModulesPath(appContext.user, (module as ReactGridModule).layout.id)}/layout/lg/${l.i}`, l)
            //     })
            // })
        },
        onDragOver: (e: React.DragEvent<HTMLDivElement>) => { console.log("Drag Over", e) },
        onResizeStop: (reactGridModule: ReactGridModule) => {
            reactGridModule.layout.updateFirebaseData(appContext.user, reactGridModule.id, reactGridModule.currentBreakpoint);
        },
        currentBreakpoint: appContext.currentBreakpoint
        // layout: (baseProps as IBaseModule & { layout: ILayout })?.layout,
        // onRemoveModule: (module: BaseModule, reactGridModule: ReactGridModule) => { 
        //     console.log("Remove Module", module, this);
        //     reactGridModule.updateFirebase()
        // },
    }
    module = new ReactGridModule(
        baseProps,
        relationProps,
        reactGridModuleProps);
    return module;
}

export const createActivityTableModule = async (baseProps: IBaseModule, appContext: TAppData, dataContext: TData) => {
    let module;
    const scheduledActivities: { [key: string]: ScheduledActivity } = await dataContext.scheduledActivities.getAllScheduledActivities(true);
    const activities: Activity[] = await dataContext.activities.getAllActivitiesFromFirebase(true);
    const activityTableModuleProps: IActivityTableModuleProps = {
        scheduledActivities,
        onCreateScheduledActivity: async (scheduledActivity: ScheduledActivity) => {

        },
        onUpdateActivity: (activity: Activity) => {
            dataContext.activities.updateActivity(appContext.user, activity, true)
        },
        onDeleteScheduledActivity: async (scheduledActivity: ScheduledActivity) => {
            const module = await dataContext.modules.getModule(scheduledActivity.id, dataContext, appContext, false);
            if (module) {
                dataContext.modules.deleteModule(module);
                module.deleteFromFirebase();
            }
            dataContext.scheduledActivities.deleteScheduledActivity(scheduledActivity);
            scheduledActivity.deleteFirebase(appContext.user);
            dataContext.calendarData.deleteScheduledActivity(scheduledActivity.getStartTimeDate(), scheduledActivity.id, dataContext, true, true);
        },
        onFinishScheduledActivity: (scheduledActivity: ScheduledActivity) => {
            dataContext.scheduledActivities.updateScheduledActivity(scheduledActivity, dataContext);
            scheduledActivity.updateFirebase(appContext.user);
        },
        onDeleteActivity: (activity: Activity) => { dataContext.activities.deleteActivity(activity, true) },
        onCreateActivity: async (activity: Activity) => {
            dataContext.activities.addActivity(activity, true)
        },
        sendToTodoList: async (activity: Activity) => {
            const todoBoardModule: TodoBoardModule = await getTodoModule(dataContext, appContext)
            todoBoardModule.createBoardItem(activity.name, activity.id);
        },
        // rowData: [],
        activities,
        startDate: getToday(),
        endDate: addDaysStringDate(getToday(), 10),

    }
    module = new ActivityTableModule(
        baseProps,
        activityTableModuleProps,
    );
    return module;
}

export const createBoardModule = async (baseProps: IBaseModule, appContext: TAppData, dataContext: TData) => {
    let module;
    const childModules = await getModuleChildren(baseProps, appContext, dataContext);
    const relationProps: IModuleRelationsProps = {
        newChildModule: async (params) => {
            return await createScheduledActivityCardModule({ user: appContext.user, id: generateId(), type: modules.CARD, name: params.name, onDelete: () => { } }, appContext, dataContext)
        },
        parentModule: null,
        childModules,
        layout: (baseProps as any).layout ? new Layout((baseProps as any).layout) : null,
        onCreateChildModule: (createdModule: BaseModule, yourSelf: BaseModule) => {
            createdModule.updateFirebase();
            yourSelf.updateFirebase();
        },
        onRemoveChildModule: (removedModule?: ModuleRelations, yourself?: ModuleRelations) => {
            removedModule.deleteFromFirebase();
            yourself.updateFirebase();
            yourself.forceRender();
            // removedModule.parentModule.layout.updateFirebaseData(appContext.user);
            // removedModule.deleteFromFirebase();
        },
    }
    const boardModuleProps: IReactGridModuleProps = {
        onDragStart: (newGrid: GridItem) => { console.log("Drag Start", newGrid) },
        onDragStop: (reactGridModule: ReactGridModule) => { console.log("Drag Stop") },
        onDragOver: (e: React.DragEvent<HTMLDivElement>) => { console.log("Drag Over", e) },
        onResizeStop: (reactGridModule: ReactGridModule) => {
            reactGridModule.layout.updateFirebaseData(appContext.user, reactGridModule.id, appContext.currentBreakpoint);
        },
        currentBreakpoint: appContext.currentBreakpoint
    }
    module = new BoardModule(baseProps, relationProps, boardModuleProps);
    childModules.forEach((m: any) => { m.parentModule = module });
    return module;
}

export const createTodoBoardModule = async (baseProps: IBaseModule, appContext: TAppData, dataContext: TData) => {
    let module;
    const childModules = await getModuleChildren(baseProps, appContext, dataContext)
    const relationProps: IModuleRelationsProps = {
        parentModule: null,
        childModules,
        layout: (baseProps as any).layout ? new Layout((baseProps as any).layout) : null,
        newChildModule: async (params) => {
            const newModule = await createScheduledActivityCardModule({ user: appContext.user, id: generateId(), type: modules.SCHEDULED_ACTIVITY_CARD, name: params.name, onDelete: () => { } }, appContext, dataContext)
            const newScheduledActivity = new ScheduledActivity({
                id: newModule.id,
                content: params.name,
                status: ActivityStatus.NOT_STARTED,
                activityId: params.activityId,
                interval: params.interval,
                startTime: params.startTime
            });
            newModule.scheduledActivity = newScheduledActivity;
            return newModule;
        },
        onCreateChildModule: (createdModule: ModuleRelations, yourSelf: ModuleRelations, params) => {
            dataContext.scheduledActivities.setScheduledActivity((createdModule as ScheduledActivityCardModule).scheduledActivity);
            dataContext.modules.addModule(createdModule, true);
            createdModule.updateFirebase();

            if (params?.date) {
                dataContext.calendarData.addScheduleToDate((createdModule as ScheduledActivityCardModule).scheduledActivity, params.date, dataContext, true, true);
            }
            yourSelf.updateFirebase();
        },
        onRemoveChildModule: (removedModule?: ModuleRelations, yourself?: ModuleRelations) => {
            yourself.updateFirebase();
            removedModule.deleteFromFirebase();
        },
    }
    const reactGridModuleProps: IReactGridModuleProps = {
        onDragStart: (newGrid: GridItem) => { console.log("Drag Start", newGrid) },
        onDragStop: (reactGridModule: ReactGridModule) => {
            reactGridModule.layout.updateFirebaseData(appContext.user, reactGridModule.id, appContext.currentBreakpoint);
        },
        onDragOver: (e: React.DragEvent<HTMLDivElement>) => { console.log("Drag Over", e) },
        onResizeStop: (reactGridModule: ReactGridModule) => {
            reactGridModule.layout.updateFirebaseData(appContext.user, reactGridModule.id, appContext.currentBreakpoint);
        },
        currentBreakpoint: appContext.currentBreakpoint,
    }

    const boardProps: IBoardModuleProps = {
        hideChecked: (baseProps as any).hideChecked,
        onScheduledActivityUpdate: async (updatedScheduledActivity: ScheduledActivityCardModule, oldDate?: string) => {
            // console.log(updatedScheduledActivity, self, oldDate);
            if (updatedScheduledActivity.scheduledActivity.startTime > 0) {
                const todoBoardModule = await getTodoModule(dataContext, appContext);
                todoBoardModule.removeChildModule(updatedScheduledActivity, updatedScheduledActivity.parentModule);
                const calendarEventsModule = await getCalendarEventsModule(dataContext, appContext);
                calendarEventsModule.onScheduledActivityUpdate(updatedScheduledActivity, oldDate);
                // todoModule.onScheduledActivityUpdate(updatedScheduledActivity, oldDate);
            }
        }
    }

    baseProps.user = appContext.user;
    module = new TodoBoardModule(
        baseProps,
        relationProps,
        reactGridModuleProps,
        boardProps
    ) as TodoBoardModule;
    module.childModules.forEach(m => { m.parentModule = module });
    return module;
}