import { asyncMap } from './../utils/Utils';
import * as React from 'react';
import { BaseModule, IBaseModule } from './modules/BaseModule';
import { NotYetImplementedModule } from './modules/NotYetImplementedModule/NotYetImplementedModule';
import { getModulesPath, modules as m } from "../utils/constants";
import { CalendarModule } from './modules/CalendarModule/CalendarModule';
import { objectHasProperties } from '../utils/Utils';
import { TData, TModules } from '../app/interface';
import { readData } from '../utils/Firebase';
import { TAppData } from '../app/AppContext';
import { createActivityTableModule, createBoardModule, createCalendarEventsModule, createCardModule, createGridLayoutModule, createScheduledActivityCardModule, createTodoBoardModule } from './moduleUtils';

export const useModules = (modulesMock: Partial<TModules>) => {
    const [allModules, setAllModules] = React.useState<{ [key: string]: BaseModule }>(modulesMock?.allModules || {});
    const [moduleFilter, setModuleFilter] = React.useState<string>("");

    const refresh = () => {
        setAllModules({ ...allModules });
    }

    const addModule = (module: BaseModule, setModules: boolean): void => {
        allModules[module.id] = module;
        if (setModules) {
            refresh();
        }
    }

    const deleteModule = (module: BaseModule) => {
        delete allModules[module.id];
        refresh();
    }

    const getModule = async (moduleId: string, dataContext: TData, appContext: TAppData, setModules?: boolean): Promise<BaseModule> => {
        if (moduleId in allModules) {
            return allModules[moduleId]
        }
        else if (appContext.user) {
            const baseModuleObject: IBaseModule = await readData(getModulesPath(appContext.user, moduleId))
            if (baseModuleObject) {
                const module: BaseModule = await getModuleType(baseModuleObject, dataContext, appContext);
                module.user = appContext.user;
                allModules[module.id] = module;
                if (setModules) {
                    refresh();
                }
                return module;
            }
        }
    }

    const getModules = async (moduleIds: string[], dataContext: TData, appContext: TAppData, setModules?: boolean): Promise<BaseModule[]> => {
        const modules = [];
        for (const moduleId of moduleIds) {
            const module = await getModule(moduleId, dataContext, appContext);
            if (module) {
                modules.push(module);
            }
        }
        setModules && refresh();
        return modules;
    }

    const getAllModules = async (dataContext: TData, appContext: TAppData, setModules?: boolean): Promise<BaseModule[]> => {
        const baseModuleObject: IBaseModule = await readData(getModulesPath(appContext.user, undefined))
        if (baseModuleObject) {
            const modules = await asyncMap(Object.values(baseModuleObject), async (baseModule: IBaseModule) => {
                const module = await getModule(baseModule.id, dataContext, appContext, setModules);
                return module;
            });
            return modules;
        }
        return [];
    }

    const hasModules = (): boolean => objectHasProperties(allModules);

    const getFunctions = () => ({ allModules, addModule, getModule, getModules, refresh, setModuleFilter, deleteModule, hasModules, setAllModules, getAllModules, ...modulesMock })
    return getFunctions();

}

export const getModuleType = async (baseProps: IBaseModule, dataContext: TData, appContext: TAppData, extra?: any): Promise<BaseModule> => {
    let module;

    if (baseProps.type === m.CARD) {
        module = await createCardModule(baseProps, appContext, dataContext);
    }
    else if (baseProps.type === m.SCHEDULED_ACTIVITY_CARD) {
        module = createScheduledActivityCardModule(baseProps, appContext, dataContext);
    }
    else if (baseProps.type === m.CALENDAR) {
        module = new CalendarModule(baseProps);
    }
    else if (baseProps.type === m.CALENDAR_EVENTS) {
        module = createCalendarEventsModule(baseProps, appContext, dataContext);
    }
    else if (baseProps.type === m.GRID_LAYOUT) {
        module = createGridLayoutModule(baseProps, appContext, dataContext);
    }
    else if (baseProps.type === m.ACTIVITY_TABLE) {
        module = createActivityTableModule(baseProps, appContext, dataContext);
    }
    else if (baseProps.type === m.BOARDS) {
        module = createBoardModule(baseProps, appContext, dataContext);
    }
    else if (baseProps.type === m.TODO_BOARD) {
        module = createTodoBoardModule(baseProps, appContext, dataContext);
    }
    else {
        module = new NotYetImplementedModule({ ...baseProps });
    }

    return module;
}

// export const getCreationDialog = (type: string): JSX.Element => {
//     if(type === modules.CARD){
//         console.log(type);
//         return CardModule.getCreationDialog();
//     }
// }