import * as React from 'react'
import { BaseModule, IBaseModule } from "../BaseModule";
import { GridHeightsSM, GridWidthsSM, ILayouts, breakpointTypes, modules } from '../../../utils/constants';
import { CardModule } from '../CardModule/CardModule';
import { PlusIcon } from '../../../icons/PlusIcon';
import InputConfirm from '../../../components/input_and_confirm/InputConfirm';
import { Row } from '../../../components/layout/row/Row';
import { Column } from '../../../components/layout/column/Column';
import { IReactGridModuleProps, ReactGridModule } from '../ReactGridModule/ReactGridModule';
import "./BoardModule.scss";
import { GridItem } from '../ReactGridModule/GridItem';
import { IModuleRelationsProps, ModuleRelations } from '../ActivityTableModule/ModuleRelations';
import { generateId } from '../../../utils/Utils';
import { removeModule } from '../../../app/utils';
import { ScheduledActivityCardModule } from '../ScheduledActivityCardModule/ScheduledActivityCardModule';
// import { baseProps } from '../../../data/mock/MockData';

export interface IBoardModuleProps {
    hideChecked?: boolean
    onScheduledActivityUpdate: (updatedScheduledActivity: ScheduledActivityCardModule, oldDate?: string) => void;
}

export class BoardModule extends ReactGridModule implements IBoardModuleProps {
    hideChecked?: boolean
    onScheduledActivityUpdate: (updatedScheduledActivity: ScheduledActivityCardModule, oldDate?: string) => void;

    constructor(baseProps: IBaseModule, moduleRelationProps: IModuleRelationsProps, reactGridProps: IReactGridModuleProps, boardProps?: IBoardModuleProps) {
        const filteredIds = boardProps?.hideChecked ? moduleRelationProps.childModules.filter(m => "checkboxChecked" in m && m.checkboxChecked).map(m => m.id) : []
        super(
            baseProps,
            moduleRelationProps,
            { ...reactGridProps, filteredIds }
        );
        Object.assign(this, boardProps);
        this.noBox = false;
        this.dataTestid = `board-module`;
    }

    getCheckedModuleIds = () => {
        return this?.hideChecked ? this.childModules.filter(m => "checkboxChecked" in m && m.checkboxChecked).map(m => m.id) : []
    }

    filterModules = (moduleIds: string[]) => {
        this.filteredIds = moduleIds
        this.forceRender();
    }

    cardArrayToObject = (cardArray: GridItem[]): { [key: string]: GridItem } => {
        return cardArray.reduce((previous, current, index, array) => {
            previous[current.i] = current;
            return previous;
        }, {})
    }

    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: new GridItem(({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.FULL,
                h: GridHeightsSM.FULL,
            }), this.id),
            [breakpointTypes.LG]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.QUARTER,
                h: GridHeightsSM.HALF,
            }, this.id)
        }
    }

    createBoardItem(name: string) {
        // const cardModule: CardModule = CardModule.createModule(
        //     { name, user: this.user }, 
        //     undefined,
        //     { parentModule: this });
        // this.addChildModule(cardModule, this, cardModule.getDefaultGrid());
        // // this.addModule(cardModule);
        // this.renderModule();
        // this.closeModal();
        // // this.onCreateCard(cardModule, this);
    }

    // removeChildModule = (id: string) => {
    //     super.removeChildModule(id);
    // }

    renderTopMenu = (): JSX.Element => {
        return <Column alignLeft className='board-top'>
            <Row>
                <div
                    data-testid='add-card-button'
                    className="center-content"
                    role="button"
                    onClick={(e) => {
                        this.toggleModal(
                            <div>
                                <InputConfirm testId='add-board-card' label='Namn på kortet' onConfirm={name => {
                                    this.createBoardItem(name);
                                }} /></div>)
                    }}>
                    Lägg till kort
                    <PlusIcon />
                </div>
            </Row>
        </Column>
    }

    onDelete = () => {
        removeModule(this);
    }

    render(): JSX.Element {
        return <div className="board-module">
            {super.render()}
        </div>
    }

    static createModule = (baseModuleProps?: Partial<IBaseModule>, reactGridProps?: Partial<IBaseModule>, relationProps?: Partial<IModuleRelationsProps>): BoardModule => {
        // static createBoardModule = (reactGridProps?: Partial<IBaseModule>, relationProps?: Partial<IModuleRelationsProps>, customBoardModuleProps?: Partial<IReactGridModuleProps>, layoutItemsIds?: string[], gridMocks?: Partial<GridItem>[]): BoardModule => {
        const boardModuleProps: IReactGridModuleProps = {
            onDragStart: (newGrid: GridItem) => { console.log("Drag Start", newGrid) },
            onDragStop: (reactGridModule: ReactGridModule) => { console.log("Drag Stop", reactGridModule) },
            onDragOver: (e: React.DragEvent<HTMLDivElement>) => { console.log("Drag Over", e) },
            onResizeStop: (reactGridModule: ReactGridModule) => {
                // reactGridModule.layout.updateFirebaseData("", reactGridModule.id);
            },
            currentBreakpoint: breakpointTypes.LG,
            ...reactGridProps
        }
        return new BoardModule(
            { ...BaseModule.getDefaultBaseModuleProps("BoardModule", modules.BOARDS), ...baseModuleProps },
            { ...ModuleRelations.getDefaultModuleRelationsProps(relationProps), ...relationProps },
            boardModuleProps);
    }

    static getCreationDialog(user: string, onCreate: (newModule: BoardModule) => void): JSX.Element {
        return <div>
            <InputConfirm
                label='Namn på lista'
                onConfirm={(name) => {
                    const newModule = BoardModule.createModule({ user, id: generateId(), name, type: modules.BOARDS })
                    onCreate(newModule);
                }}
            />
        </div>
    }

    toFirebaseObject() {
        return { ...super.toFirebaseObject(), hideChecked: this.hideChecked };
    }

}