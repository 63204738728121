import * as React from 'react'
import "./SubBar.scss";
import { AppContext } from './AppContext';
import { useNavigate } from "react-router-dom";
import { modules as module, modules } from '../utils/constants';
import { ModuleCreator } from '../module/module_creator/ModuleCreator';
import { BaseModule } from '../module/modules/BaseModule';
import { Row } from '../components/layout/row/Row';
import { DataContext } from './DataContext';
import { addModuleToRootLayout } from './utils';
import { ModuleRelations } from '../module/modules/ActivityTableModule/ModuleRelations';
import { CardModule } from '../module/modules/CardModule/CardModule';
import { ScheduledActivityCardModule } from '../module/modules/ScheduledActivityCardModule/ScheduledActivityCardModule';
import { generateId } from '../utils/Utils';
import { TodoBoardModule } from '../module/modules/TodoBoardModule/TodoBoardModule';
import { BoardModule } from '../module/modules/BoardModule/BoardModule';

const SubBar = () => {

    const appContext = React.useContext(AppContext);
    const dataContext = React.useContext(DataContext);

    return (
        <Row className="subbar">
            <div className="subbar-container">
                <Row>
                    <ModuleCreator
                        horisontal
                        closeIconTestid="subbar__close-icon"
                        options={[
                            {
                                name: module.CARD,
                                onClick: CardModule.getCreationDialog(appContext.user, async (newModule: CardModule) => {
                                    await addModuleToRootLayout(
                                        dataContext,
                                        appContext,
                                        newModule,
                                        true
                                    );
                                }),
                                testId: 'create-card',
                                title: 'Skapa nytt kort'
                            },
                            {
                                name: module.SCHEDULED_ACTIVITY_CARD,
                                onClick: ScheduledActivityCardModule.getCreationDialog(appContext.user, async (newModule: ScheduledActivityCardModule) => {
                                    dataContext.scheduledActivities.setScheduledActivity(newModule.scheduledActivity);
                                    newModule.scheduledActivity.updateFirebase(appContext.user)
                                    await addModuleToRootLayout(
                                        dataContext,
                                        appContext,
                                        newModule,
                                        true
                                    );
                                }),
                                testId: 'create-scheduled',
                                title: 'Skapa en ny uppgift'
                            },
                            {
                                name: module.BOARDS,
                                onClick: BoardModule.getCreationDialog(appContext.user, async (newModule: BoardModule) => {
                                    await addModuleToRootLayout(
                                        dataContext,
                                        appContext,
                                        (newModule),
                                        true
                                    );
                                }),
                                testId: 'create-board',
                                title: 'Skapa en ny lista'
                            },
                            // {
                            //     name: module.SCHEDULED_ACTIVITY_CARD,
                            //     onClick: () => {
                            //         const newModule = ScheduledActivityCardModule.createModule({ id: generateId(), user: appContext.user })
                            //         return newModule;
                            //     },
                            //     testId: 'create-scheduled',
                            //     title: 'Skapa en ny uppgift'
                            // },
                        ]}
                        // onCreate={async (module: BaseModule) => {
                        //     if(module instanceof ScheduledActivityCardModule) {
                        //         dataContext.scheduledActivities.addScheduledActivity(module.scheduledActivity);
                        //         module.scheduledActivity.updateFirebase(appContext.user)
                        //     }
                        //     await addModuleToRootLayout(
                        //         dataContext,
                        //         appContext,
                        //         (module as ModuleRelations),
                        //         true
                        //     );
                        //     module.updateFirebase();
                        // }}
                        user={appContext.user}
                        dataTestid={''}
                    />
                </Row>
            </div>
        </Row>
    )
}

export { SubBar }
