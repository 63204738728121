import * as React from 'react'

const Label = ({ text, bold = false, className = "", onClick = null }) => {
    return (<>
        {bold && <b> <label onClick={() => {
            if (onClick) {
                onClick(text);
            }
        }}
            className={"label" + (className ? " " + className : "")}>{text}
        </label>
        </b>}
        {!bold && <label onClick={() => {
            if (onClick) {
                onClick(text);
            }
        }} className={"label" + (className ? " " + className : "")}>{text}</label>}
    </>
    )
}

export { Label }
