import * as React from 'react';
import { IBaseModule } from "../BaseModule";
import { ActivityStatus, breakpointTypes, getModulesPath, GridHeightsLG, GridHeightsSM, GridWidthsLG, GridWidthsSM, ILayouts, modules } from '../../../utils/constants';
import { writeData } from '../../../utils/Firebase';
import { CalendarData } from '../../../components/calendar/CalendarData';
import { Activity } from '../../../data/activity/Activity';
import { Column } from '../../../components/layout/column/Column';
import { MarginBottom } from '../../../components/layout/margin/MarginBottom';
import { Button } from '../../../components/button/Button';
import { getNow, getToday, Millis, convertTimestampToStringDate, addDaysStringDate, convertStringTimeAndDateToTimestamp, convertTimestampToStringDateAndTime, convertTimestampToTimepickerStringTime } from '../../../utils/TimeUtils';
import StringIcons, { IconTypes } from '../../../components/string_icons/StringIcons';
import { ILog } from '../../../components/calendar/Calendar';
import './Upcoming.scss'
import { ActivityPicker } from '../../../components/pickers/activity_picker/ActivityPicker';
import { TData } from '../../../app/interface';
import { generateId } from '../../../utils/Utils';
import InputConfirm from '../../../components/input_and_confirm/InputConfirm';
import { TextArea } from '../../../components/textarea/TextArea';
import { Input } from '../../../components/input/Input';
import { GridItem } from '../ReactGridModule/GridItem';
import { ScheduledActivityCardModule } from '../ScheduledActivityCardModule/ScheduledActivityCardModule';
import { TAppData } from '../../../app/AppContext';
import { IModuleRelationsProps, ModuleRelations } from '../ActivityTableModule/ModuleRelations';
import { SchedulingService } from '../../../app/SchedulingService/SchedulingService';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import { Row } from '../../../components/layout/row/Row';
import { MarginRight } from '../../../components/layout/margin/MarginRight';
import { ExclamationIcon } from '../../../icons/ExclamationIcon';

export interface ICalendarEventsModule {
    startDate: string;
    endDate: string;
    dates: CalendarData[],
    updateCalendarData: (calendarData: CalendarData) => void;
    // onNewScheduledActivity: (scheduledActivityCardModule: ScheduledActivityCardModule, date: string, self: CalendarEventsModule) => void;
    onScheduledActivityClick: (activity: Activity) => void;
    onTemporaryScheduledActivityCreated: (newScheduledActivity: ScheduledActivityCardModule, calendarData: CalendarData) => void,
    onScheduledActivityUpdate: (ScheduledActivityCardModule, oldDate?: string) => void
    onDeleteScheduledActivity: (scheduledActivity: ScheduledActivityCardModule, calendarDate: CalendarData) => void;
    onFinishScheduledActivity: (finishedScheduledActivity: ScheduledActivityCardModule, newScheduledActivity: ScheduledActivityCardModule) => void;
    activities: Activity[];
    diary: JSX.Element;

}

export class CalendarEventsModule extends ModuleRelations implements ICalendarEventsModule {
    startDate: string;
    endDate: string;
    dates: CalendarData[];
    // onNewScheduledActivity: (scheduledActivityCardModule: ScheduledActivityCardModule, date: string, self: CalendarEventsModule) => void;
    onScheduledActivityUpdate: (ScheduledActivityCardModule, oldDate?: string) => void
    onDeleteScheduledActivity: (scheduledActivity: ScheduledActivityCardModule, oldcalendarDate: CalendarData) => void;
    activities: Activity[];
    updateCalendarData: (calendarData: CalendarData) => void;
    onTemporaryScheduledActivityCreated: (newScheduledActivity: ScheduledActivityCardModule, calendarData: CalendarData) => void;
    onScheduledActivityClick: (activity: Activity) => void;
    onFinishScheduledActivity: (finishedScheduledActivity: ScheduledActivityCardModule, newScheduledActivity: ScheduledActivityCardModule) => void;
    activitiesToSchedule: { scheduledActivityCardModule: ScheduledActivityCardModule, interval: number, date: CalendarData, startTime: string, addSchedule: boolean, alreadyExists: boolean }[] = [];
    diary: JSX.Element;

    constructor(
        props: IBaseModule,
        moduleRelationsProps: IModuleRelationsProps,
        calendarEventsModuleProps: ICalendarEventsModule
    ) {
        super(props, moduleRelationsProps);
        this.autoPackingDimensions = { w: GridWidthsLG.QUARTER, h: GridHeightsLG.HALF };
        Object.assign(this, calendarEventsModuleProps);
        this.dataContextArgs = ["activities.activities"/* , "calendarData.calendarData" */]
    }

    onDataContextUpdate = async (dataContext: TData, appContext: TAppData): Promise<void> => {
        if (!this.noDataContextSync && dataContext) {
            this.activities = dataContext.activities.activities;
            // const startDate = getToday()
            // const endDate = addDaysStringDate(startDate, 10);
            // this.dates = await dataContext.calendarData.getDates(startDate, endDate, true, false, dataContext);
            // const allModuleIds = this.dates.reduce((previous, current) => {
            //     current.schedule.forEach(s => {
            //         if (!previous.includes(s.id)) {
            //             previous.push(s.id);
            //         }
            //     });
            //     return previous;
            // }, [])
            // this.childModules = await dataContext.modules.getModules(allModuleIds, dataContext, appContext);
            this.forceRender();
        }
    }

    renderTopMenu(): JSX.Element {
        return null;
    }

    updateModuleFirebase() {
        const firebaseData: Omit<IBaseModule, "onDelete"> = { id: this.id, user: this.user, type: this.type, name: this.name }
        writeData(getModulesPath(this.user, this.id), firebaseData);
    }

    getCreationDialog(): JSX.Element {
        return <div>creation dialog</div>
    }


    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.QUARTER,
                h: GridHeightsSM.HALF,
            }, this.id),
            [breakpointTypes.LG]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsLG.QUARTER,
                h: GridHeightsSM.HALF,
            }, this.id)
        }
    }

    deleteScheduledActivityFromDate = (calendarData: CalendarData, scheduledActivity: ScheduledActivityCardModule) => {
        calendarData.schedule = calendarData?.schedule.filter(s => s.id !== scheduledActivity.id);
    }

    addScheduledActivityToDate = (calendarData: CalendarData, scheduledActivity: ScheduledActivityCardModule) => {
        calendarData.schedule?.push(scheduledActivity.scheduledActivity);
    }

    updateScheduledActivity = (scheduledActivity: ScheduledActivityCardModule, oldDate: string) => {
        const newDate = convertTimestampToStringDate(scheduledActivity.scheduledActivity.startTime);
        if (newDate !== oldDate) {
            const oldCalendarD = this.dates.find(d => d.date === oldDate)
            const newCalendarD = this.dates.find(d => d.date === newDate)
            this.deleteScheduledActivityFromDate(oldCalendarD, scheduledActivity);
            this.addScheduledActivityToDate(newCalendarD, scheduledActivity);
            // this.onScheduledActivityUpdate(scheduledActivity, newCalendarD.date);
        }
        else {
            const oldCalendarD = this.dates.find(d => d.date === oldDate)
            // this.onScheduledActivityUpdate(scheduledActivity, oldCalendarD.date);
        }
        this.forceRender();
    }

    renderContent = (schedule: ScheduledActivityCardModule[], cd: CalendarData) => {
        schedule
            // .map(s => s.scheduledActivity)
            .sort((c, d) => {
                const a = c.scheduledActivity;
                const b = d.scheduledActivity;
                if (a?.isFinished() && !b?.isFinished()) {
                    return -1;
                }
                else if (!a?.isFinished() && b?.isFinished()) {
                    return 1;
                }
                else if (a?.isFinished() && b?.isFinished()) {
                    if (a.startTime && !b.startTime) {
                        return 1;
                    }
                    else if (!a.startTime && b.startTime) {
                        return -1;
                    }
                    else if (a.startTime && b.startTime) {
                        return a.startTime < b.startTime ? -1 : 1
                    }
                }
                else if (!a?.isFinished() && !b?.isFinished()) {
                    return a?.startTime < b?.startTime ? -1 : 1;
                }
            })


        return <>
            {
                schedule.map((scheduledActivity: ScheduledActivityCardModule, key) => {
                    if (!scheduledActivity) {
                        return null;
                    }
                    // const onScheduledActivityUpdate = scheduledActivity.onUpdateScheduledActivity;
                    // scheduledActivity.onUpdateScheduledActivity = (scheduledActivity: ScheduledActivityCardModule, oldDate: string) => {
                    //     // this.updateScheduledActivity(scheduledActivity, oldDate, previousDate);
                    //     this.updateScheduledActivity(scheduledActivity, oldDate, cd.date);
                    //     onScheduledActivityUpdate(scheduledActivity, oldDate);
                    // }
                    return <React.Fragment key={key}>{scheduledActivity.renderModule()}</React.Fragment>
                })
            }

            {cd.data?.logs?.length > 0 && cd.data?.logs.map((log: ILog, key) => {
                return <div onClick={() => {
                    this.toggleModal(<div>{log.content}</div>)
                }} key={key}>- {log.title}</div>
            })}
        </>
    }

    addScheduledActivity = async (name: string, activityId: string, startTime, interval: number, cd: CalendarData) => {

        const newScheduledActivity: ScheduledActivityCardModule = await this.addChildModule(
            { name, activityId, interval, startTime },
            this
        ) as ScheduledActivityCardModule;

        const d = this.dates.find(d => d.date === cd.date);
        if (!d.schedule) {
            d.schedule = [];
        }
        if (!d?.schedule?.some(s => s.id === newScheduledActivity.id)) {
            d?.schedule.push(newScheduledActivity.scheduledActivity);
            cd = d;
        }

        this.onCreateChildModule(newScheduledActivity, this, { date: cd.date });
        this.closeModal();
        // this.forceRender();
    }

    handleDayHeaderClick = (calendarData: CalendarData) => {
        this.toggleModal(<Column alignLeft>
            <h1>{calendarData.date}</h1>
            <Button label={'Lägg till en befintlig aktivitet'} onClick={() => {
                this.toggleModal(<ActivityPicker activities={this.activities} onSelect={async (activity: Activity) => {
                    this.addScheduledActivity(activity.name, activity.id, undefined, activity.interval, calendarData);
                }} />)
            }} />
            <MarginBottom />
            <Button label={'Lägg till en temporär aktivitet'} onClick={() => {
                this.toggleModal(
                    <InputConfirm
                        label='Namn på aktiviteten'
                        onConfirm={name => {
                            this.addScheduledActivity(name, undefined, undefined, undefined, calendarData);
                        }
                        }
                    />)
            }} />
            <MarginBottom />
            <Button
                label="Skapa ett dagboksinlägg"
                onClick={() => {
                    let title = "";
                    let text = "";
                    this.toggleModal(<div>
                        <Input onChange={t => {
                            title = t;
                        }} />
                        <MarginBottom />
                        <TextArea
                            rows={10}
                            cols={50}
                            onChange={txt => {
                                if (!calendarData?.data) {
                                    calendarData.data = { logs: [], icons: [] }
                                }
                                if (!calendarData?.data?.logs) {
                                    calendarData.data.logs = [];
                                }
                                text = txt
                            }}
                        />
                        <Button label="Skapa" onClick={() => {
                            calendarData?.data?.logs?.push({ id: generateId(), content: text, created: getNow(), title })
                            this.updateCalendarData(calendarData)
                            this.closeModal();
                        }} />
                        <Button
                            label="Visa dagbok"
                            onClick={() => {
                                this.toggleModal(<div>
                                    {this.diary}
                                    {/* {calendarData?.data?.logs?.map((log, key) => {
                                        return <div key={key}>
                                            <div>{log.title}</div>
                                            <div>{log.content}</div>
                                        </div>
                                    })
                                    } */}
                                </div>
                                )
                            }}
                        />
                        {calendarData?.data?.logs?.map((log, key) => {
                            return <div key={key}>
                                <div>{log.title}</div>
                                <div>{log.content}</div>
                            </div>
                        })
                        }
                    </div>
                    )
                }}
            />
        </Column>)
    }
    getRows = () => {
        const today = getToday();
        let i = 0;
        const t = [];
        let allExpiredActivities = [];
        for (const calendarData of this.dates) {
            const isToday = calendarData.date === today;
            const activitiesExpiredMoraThanHoursAgo = calendarData.schedule.filter(s => {
                if (!s) {
                    return false;
                }
                const scheduledActivityCardModule: ScheduledActivityCardModule = this.childModules.find(m => m.id === s.activityId) as ScheduledActivityCardModule;
                const timeSinceStart = scheduledActivityCardModule?.scheduledActivity?.startTime + (Millis.hour * 3);
                const now = getNow();
                const isFinished = scheduledActivityCardModule?.scheduledActivity?.isFinished()
                return timeSinceStart < now && !isFinished
            });
            allExpiredActivities = allExpiredActivities.concat(activitiesExpiredMoraThanHoursAgo);
            const icons = calendarData.data?.icons;
            const nCompletedTasks = calendarData.schedule.filter(s => (this.childModules.find(m => m.id === s.activityId) as ScheduledActivityCardModule)?.scheduledActivity?.status === ActivityStatus.DONE)?.length;
            const totalTasks = calendarData.schedule.length;
            const schedule = calendarData.schedule.map(s => this.childModules.find(m => m.id === s.id) as ScheduledActivityCardModule).filter(s => s);
            t.push(
                <React.Fragment key={i}>
                    <Column className="upcoming-calendar-events-row" dataTestid="upcoming-calendar-event-row">
                        <div
                            data-testid="upcoming-calendar-event-header"
                            onClick={() => {
                                this.handleDayHeaderClick(calendarData);
                            }}
                            className={`upcoming-calendar-event-header${isToday ? " today" : ""}`}
                        >
                            <div className="margin-right">
                                {calendarData.date} {nCompletedTasks + "/" + totalTasks}
                            </div>
                            {icons?.length > 0 && icons.map((icon: IconTypes, key: number) => {
                                return <div key={key}>
                                    {StringIcons(icon as IconTypes, () => { })}
                                </div>
                            })}
                        </div>
                        {this.renderContent(schedule, calendarData)}
                    </Column>
                </React.Fragment>
            )
            i++;
        }
        return t;
    }

    autoGenerateButton = () => <Button label={'Generera schema'} onClick={() => {
        // this.activities.forEach(activity => {
        this.activitiesToSchedule = [];
        this.activities.forEach((activity, i) => {
            const dates = SchedulingService.getDatesValidForActivity(activity, this.dates);
            const mergedDates: { date: string, startTime: number }[] = dates?.reduce((previous, current) => {
                current.validDates.forEach(date => {
                    if (!previous.some(d => d.date === date)) {
                        previous.push({ date, startTime: current.startTime || 0 });
                    }
                });
                return previous;
            }, []);

            // mergedDates?.forEach(date => {
            mergedDates?.forEach((mergedDate, j) => {
                // const d = this.dates.find(d => d.date === date);
                const d = this.dates.find(d => d.date === mergedDate.date);
                const startTime = convertTimestampToTimepickerStringTime(mergedDate.startTime);
                const newScheduledActivityCardModule: ScheduledActivityCardModule = ScheduledActivityCardModule.createModule(
                    { id: generateId(), name: activity.name, type: modules.SCHEDULED_ACTIVITY_CARD, user: this.user },
                    undefined,
                    { parentModule: this, onRemoveChildModule: () => { } },
                    { /* startTime: startTimeNumber, */
                        activityId: activity.id
                    }
                )
                const test = this.dates.find(d => d.date === mergedDate.date)/* .scheduleHasActivity(newScheduledActivityCardModule.id) */
                const activityAlreadyExists = test?.scheduleHasActivity(newScheduledActivityCardModule.scheduledActivity.activityId);
                this.activitiesToSchedule.push({ scheduledActivityCardModule: newScheduledActivityCardModule, interval: activity.interval, date: d, startTime: startTime, addSchedule: false, alreadyExists: activityAlreadyExists })
            })
        })

        this.toggleModal(<Column alignLeft>
            {this.activitiesToSchedule
                .sort((a, b) => a.date.date < b.date.date ? -1 : 1)
                .filter(a => !a.date.scheduleHasActivity(a.scheduledActivityCardModule.id))
                .map((a: { scheduledActivityCardModule: ScheduledActivityCardModule, interval: number, date: CalendarData, startTime: string, addSchedule: boolean, alreadyExists }, key) => {
                    return <Row>
                        {a.date.date}
                        <MarginRight />
                        {a.startTime}
                        <MarginRight />
                        <Checkbox label={a.scheduledActivityCardModule.name} checked={a.addSchedule} onChange={checked => {
                            a.addSchedule = checked;
                        }} />
                        {a.alreadyExists ? <>
                            <ExclamationIcon className={undefined} /><span>Aktiviteten finns redan</span>
                        </> : null}
                    </Row>
                })}
            <Button label={'Välj valda aktiviteter'} onClick={() => {
                this.activitiesToSchedule.forEach(a => {
                    console.log(a);
                    a.scheduledActivityCardModule.scheduledActivity.startTime = convertStringTimeAndDateToTimestamp(a.date.date, a.startTime);
                    if (a.addSchedule) {
                        this.addScheduledActivity(a.scheduledActivityCardModule.name, a.scheduledActivityCardModule.scheduledActivity.activityId, a.scheduledActivityCardModule.scheduledActivity.startTime, a.interval, a.date);
                    }
                })
                this.closeModal();
            }} />
        </Column>);
    }} />

    render(): JSX.Element {
        const rows = this.getRows();
        return <div data-testid="upcoming-events" className="upcoming-events">
            {this.autoGenerateButton()}
            {rows}
        </div>
    }

    toFirebaseObject() {

    }

    updateFirebase() {

    }

}