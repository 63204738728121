import firebase from "firebase";
import { clearObjectOfUndefines } from "./Utils";
import { clearObjectAndNestedObjectAndArraysOfUndefines } from "../app/utils";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const createUser = async (email: string, password: string) => {
  const firebaseSession = await getFirebaseSession();
  const userCredential = await firebaseSession.auth().createUserWithEmailAndPassword(email, password)
  return userCredential.user.uid;
}

const StartFirebase = async (): Promise<firebase.app.App> => {
  if (!firebase.apps.length) {
    return await firebase.initializeApp(firebaseConfig);
  } else {
    return await firebase.app();
  }
};

export const getUser = async (): Promise<string> => {
  getFirebaseSession().then(firebaseSession => {
    if (firebaseSession) {
      //@ts-ignore
      firebaseSession.auth().onAuthStateChanged(val => {
        if (val) {
          return val.uid;
        }
      })
    }
  });
  return ""
}

const writeData = async (path: string, data: any, from?: string) => {
  const pathRegex = /\/modules$/;
  if (pathRegex.test(path)) {
    console.error("disallowed path, ends in just \"modules\"")
    return;
  }
  data = clearObjectAndNestedObjectAndArraysOfUndefines(data);
  if (from) {
    console.log(from);
  }
  // console.log(data);
  if (!path || path.includes("undefined")) {
    // console.log('did not write data due to undefined')
    // console.log(path);
  }
  else {
    if (path?.split("/")?.length < 2) {
      return ""
    }
    if (path === "" || path === "users" || path === "users/keva") {
      return;
    }
    if (Array.isArray(data)) {
      data = data.filter(datum => datum);
    }
    // console.log(path, data);
    const f = await StartFirebase();
    f.database().ref(path).set(data);
  }
};

const readData = async (path: string) => {
  // console.log("reading data from path:", path)
  if (!path) {
    return null;
  }

  const f = await StartFirebase();
  const ref = f.database().ref(path);
  return new Promise<any>((resolve, reject) => {
    ref.once("value", (snapshot) => {
      const val = snapshot.val();
      resolve(val);
    });
  })
};

export const readAll = async (basePath: string, ids: string[]) => {
  const allData = []
  for (const id of ids) {
    const path = `${basePath}/${id}`
    const data = await readData(path);
    allData.push(data);
  }
  return allData;
}

export const subscribe = async (path: string, callback) => {
  const f = await StartFirebase();
  const ref = f.database().ref(path);
  ref.on("value", (snapshot) => {
    const val = snapshot.val();
    callback(val);
  })
}

const deleteData = async (path: string) => {
  console.log('deleting path:', path);
  if (path) {
    const f = await StartFirebase();
    f.database().ref(path).remove()
  }
}

const deleteFolderRecursive = async (path: string = "") => {
  // const f = await StartFirebase();
  // f.database().
  if (path) {
    deleteData(path);
  }
}

const getFirebaseSession = async (): Promise<firebase.app.App> => {
  const f = await StartFirebase();
  return f;
}

const createBackup = (user) => {
  readData(`users/${user}`).then(data => {
    writeData(`backups/${user}`, data);
  })
}

const restoreBackup = user => {
  readData(`backups/${user}`).then(data => {
    writeData(`users/${user}`, data);
  })
}

export { writeData, readData, deleteData, deleteFolderRecursive, getFirebaseSession, createBackup, restoreBackup, createUser };
