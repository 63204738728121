import * as React from 'react'
import { Fragment, useState } from 'react'
import { Tag } from './Tag';
import "./tags.scss"
import { generateId } from '../../utils/Utils';
import InputConfirm from '../input_and_confirm/InputConfirm';
import { Row } from '../layout/row/Row';
import Modal from '../modal/Modal';
import { PlusIcon } from '../../icons/PlusIcon';
import { Label } from '../label/Label';
import { DataContext } from '../../app/DataContext';
import { CloseIcon } from '../../icons/CloseIcon';
import { Column } from '../layout/column/Column';

export interface ITag {
    id: string;
    name: string;
}

const Tags = ({ onClose = undefined, hideCreateTag = false, initialSelectedTags = [], selectable = true, onChange = null, moduleid = "" }) => {
    
    const dataContext = React.useContext(DataContext)
    const [selectedTags, setSelectedTags] = useState<string[]>(initialSelectedTags)
    const [showCreateTag, setShowCreateTag] = React.useState(false);

    const createTag = async name => {
        dataContext.tags.addTag({ id: generateId(), name })
        setShowCreateTag(false);
    }

    const renderCreateTags = () => <Row>
        <PlusIcon onClick={() => setShowCreateTag(!showCreateTag)} />
        <Label text="Skapa tagg" />
    </Row>

    const renderTags = () => {
        const allTags = dataContext.tags.tags ? Object.values(dataContext.tags.tags) : [];
        return <div className="tags">
            {allTags?.map((tag, index) => {
                const isSelected = selectedTags.some(s => s === tag.id);
                return <Fragment key={index}>
                    <Tag tag={tag} selectable={selectable} selected={isSelected} onRemove={() => {
                        dataContext.tags.removeTag(tag);
                    }}
                        onClick={async (isSelected) => {
                            if (isSelected) {
                                selectedTags.push(tag.id);
                                setSelectedTags([...selectedTags])
                                if (onChange) {
                                    onChange(selectedTags);
                                }
                            }
                            else {
                                const filteredTags = selectedTags.filter(t => t !== tag.id)
                                if (onChange) {
                                    onChange(filteredTags);
                                }
                                setSelectedTags(filteredTags);
                            }
                            // await dataContext.calendarData.createEventLog("clicked tag", tag.name, LogTypeEnums.TAG, true);
                            // const prioedTags = await dataContext.tags.getPrioedTags(dataContext);
                            // setPrioritizedTags(prioedTags);
                            // console.log(prioedTags);
                        }} /></Fragment>
            })}
        </div>
    }
    return (
        <div>
            <Column alignLeft>
                {onClose && <CloseIcon dataTestid="tagsCloseIcon" onClick={onClose} />}
                {!hideCreateTag && renderCreateTags()}
                <Modal showModal={showCreateTag} setShowModal={setShowCreateTag}>
                    <InputConfirm label='Skapa tagg' className="tag-name" onConfirm={val => {
                        if (val) {
                            createTag(val)
                        }
                    }} />
                </Modal>
                {renderTags()}
            </Column>
        </div>
    )
}

export { Tags }