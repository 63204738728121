import * as React from 'react'
import { useState } from 'react'
import { Checkbox as MaterialCheckbox } from "@mui/material";

interface ICheckbox {
    checked?: boolean;
    onChange?: (checked: boolean, label?: string, e?: any) => void;
    disabled?: boolean;
    canChange?: boolean;
    className?: string;
    label?: string;
    labelAlignment?: "left" | "right" | "top";
    textColor?: string;
    style?: any
    testId?: string;
}

const Checkbox = ({ checked = false, onChange, canChange = true, label, className, labelAlignment = "left", textColor = null, style = null, disabled, testId }: ICheckbox) => {
    const [isChecked, setIsChecked] = useState(checked);

    React.useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    const onC = (e) => {
        if (canChange === true) {
            const newChecked = !isChecked;
            setIsChecked(newChecked);
            if (onChange) {
                onChange(newChecked, label, e);
            }
        }
    }

    return <div data-testid={testId} onClick={onC} style={{
        display: 'flex',
        flexDirection: labelAlignment !== "top" ? 'row' : 'column',
        alignItems: 'center',
        justifyContent: labelAlignment === "right" ? 'row-reverse' : 'flex-start',
        color: textColor, ...style
    }}
    >
        {/* {!renderLabelRight && label} */}
        {label}
        <MaterialCheckbox className={className} disabled={disabled} checked={isChecked}/*  onChange={onC} */ />
        {/* {renderLabelRight && label} */}
    </div>

}

export { Checkbox }
