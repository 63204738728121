import { IRule } from './../../../components/activity_generator/interfaces';

class Rule implements IRule {
    id: string;
    func: string;
    funcParams?: any;
    active?: boolean;

    constructor(rule: IRule) {
        Object.assign(this, rule);
    }

}

export {Rule}