import * as React from 'react'
import { CardModule, ICardModuleProps } from '../CardModule/CardModule';
import { IScheduledActivity, ScheduledActivity } from '../../../components/activity_generator/ScheduledActivity';
import { deleteData, writeData } from '../../../utils/Firebase';
import { ActivityStatus, data, getDataPath, getModulesPath, modules } from '../../../utils/constants';
import ScheduledActivityViewer from './ScheduledActivityViewer';
import { IModuleRelationsProps } from '../ActivityTableModule/ModuleRelations';
import { BaseModule, IBaseModule } from '../BaseModule';
import { generateId } from '../../../utils/Utils';
import { Row } from '../../../components/layout/row/Row';
import { Column } from '../../../components/layout/column/Column';
import InputConfirm from '../../../components/input_and_confirm/InputConfirm';
import { removeModule } from '../../../app/utils';
import "./ScheduledActivityCard.scss";
import { CheckIcon } from '../../../icons/CheckIcon';
import { covertTimestampToStringTime } from '../../../utils/TimeUtils';
import { CalendarData } from '../../../components/calendar/CalendarData';

export interface IScheduledActivityCardModuleProps {
    scheduledActivity: ScheduledActivity;
    onUpdateScheduledActivity: (updatedScheduledActivity: ScheduledActivityCardModule, oldDate?: string) => void;
}

export class ScheduledActivityCardModule extends CardModule implements IScheduledActivityCardModuleProps {
    scheduledActivity: ScheduledActivity;
    onUpdateScheduledActivity: (updatedScheduledActivity: ScheduledActivityCardModule, oldDate?: string) => void;

    constructor(
        baseProps,
        cardModuleProps: ICardModuleProps,
        scheduledActivityCardProps: IScheduledActivityCardModuleProps,
        relationProps: IModuleRelationsProps
    ) {
        super(baseProps, cardModuleProps, relationProps);
        if (this.checkbox === undefined) {
            this.checkbox = true;
            this.checkboxChecked = false;
        }
        Object.assign(this, scheduledActivityCardProps);
    }

    getDateFromCalendarEventsModule = () => {
        const date = (this.parentModule as any)?.dates?.find((d: CalendarData) => {
            const foundDate = d.schedule.find(s => s.id === this.scheduledActivity.id)
            if (foundDate) {
                return d;
            }
        });
        return date?.date || "";
    }

    getScheduledActivityViewerDialog = () => {
    const oldDate = this.scheduledActivity.getStartTimeDate() || this.getDateFromCalendarEventsModule();
    return <ScheduledActivityViewer
            hideHeader
            scheduledActivity={this.scheduledActivity}
            onDeleteScheduledActivity={() => {
                removeModule(this);
            }}
            onUpdateScheduledActivity={(scheduledActivity) => {
                this.scheduledActivity = scheduledActivity;
                this.onUpdateScheduledActivity(this, oldDate);
            }}
        />
    }

    scheduledActivityContainerContent = () => {
        return this.scheduledActivity && this.getScheduledActivityViewerDialog()
    }

    containerContent = () => {
        return <Column alignLeft>
            <Row>
                {this.scheduledActivityContainerContent()}
                {this.createCardDialog()}
                {this.renderSettings()}
            </Row>
            {this.renderGrid()}
        </Column>
    }

    toFirebaseObject() {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            scheduledActivity: this.scheduledActivity?.id || undefined,
            layout: { lg: this.layout?.lg, sm: this.layout?.lg },
            parentModule: this.parentModule?.id,
            checkbox: this.checkbox,
            checkboxChecked: this.checkboxChecked
        }
    }

    deleteFromFirebase() {
        deleteData(getDataPath(this.user, data.SCHEDULED_ACTIVITY, this.id))
        deleteData(getModulesPath(this.user, this.id))
    }

    static createModule = (baseModuleProps?: Partial<IBaseModule>, cardModuleProps?: Partial<ICardModuleProps>, relationProps?: Partial<IModuleRelationsProps>, scheduledActivityProps?: Partial<IScheduledActivity>): ScheduledActivityCardModule => {
        const moduleRelations: IModuleRelationsProps = {
            newChildModule: async (params?: any) => {return null},
            parentModule: null,
            childModules: [],
            layout: null,
            ...relationProps
        }

        const cardProps: ICardModuleProps = {
            containerOnDragStop: (self: BaseModule) => { },
            containerOnResizeStop: (self: BaseModule) => {
                // self.updateFirebase();
            },
            modulePicker: null,
            ...cardModuleProps
        }

        const scheduledActivityCardProps: IScheduledActivity = {
            id: baseModuleProps.id,
            content: baseModuleProps.name,
            status: ActivityStatus.NOT_STARTED,
            ...scheduledActivityProps
        }
        const scheduledActivity = new ScheduledActivity(scheduledActivityCardProps);

        const newCardModule = new ScheduledActivityCardModule(
            BaseModule.getDefaultBaseModuleProps(
                "",
                modules.SCHEDULED_ACTIVITY_CARD,
                baseModuleProps
            ),
            cardProps,
            { scheduledActivity, onUpdateScheduledActivity: () => { } },
            moduleRelations
        )
        if (newCardModule.parentModule) {
            newCardModule.onCreateChildModule = newCardModule.parentModule.onCreateChildModule;
            newCardModule.onRemoveChildModule = newCardModule.parentModule.onRemoveChildModule;
        }
        else {
            newCardModule.onCreateChildModule = moduleRelations.onCreateChildModule;
            newCardModule.onRemoveChildModule = moduleRelations.onRemoveChildModule;
        }
        return newCardModule;
    }

    renderContent(): JSX.Element {
        return <div onClick={() => {
            this.showCardContainer()
        }}>
            {this.name}{this.scheduledActivity?.startTime > 0 && " " + covertTimestampToStringTime(this.scheduledActivity.startTime)}
        </div>
    }

    renderCardStandard(): JSX.Element {
        return <>
            {!this.checkboxChecked && this.renderCheckbox()}
            {this.renderContent()}
            {this.checkboxChecked && <CheckIcon />}
        </>
    }

    updateFirebase() {
        writeData(this.getFirebasePath(), this.toFirebaseObject());
        this.scheduledActivity && this.scheduledActivity.updateFirebase(this.user)
    }
    
    static getCreationDialog = (user: string, onCreate: (newModule: ScheduledActivityCardModule) => void): JSX.Element => {
        return <div>
            <InputConfirm
                label='Namn på uppgift'
                onConfirm={(name) => {
                    const type = modules.SCHEDULED_ACTIVITY_CARD;
                    // const cardModuleProps: ICardModuleProps = {
                    //     containerOnDragStop: (self: BaseModule) => { },
                    //     containerOnResizeStop: (self: BaseModule) => { }
                    // }
                    // Object.assign(this, cardModuleProps)
                    const newModule = ScheduledActivityCardModule.createModule({ user, id: generateId(), name, type });
                    onCreate(newModule);
                }}
            />
        </div>
    }

}