import { getDuration, getNow, getTimeLeft, timestampHasPassed, convertTimestampToStringDateAndTime, convertTimestampToStringDate } from './../../utils/TimeUtils';
import { Activity } from '../../data/activity/Activity';
import { clearObjectOfUndefines } from '../../utils/Utils';
import { deleteData, writeData } from '../../utils/Firebase';
import { ActivityStatus, data, getDataPath } from '../../utils/constants';
import { generateId } from '../../utils/Utils';
import { TData } from '../../app/interface';
import { CalendarData } from '../calendar/CalendarData';

export interface IScheduledActivity {
    id: string;
    // date: string;
    latestStartTime?: string;
    latestCompleted?: number;
    content: string;
    status: ActivityStatus;
    timed?: boolean;
    startTime?: number;
    lastStartTime?: number;
    stopTime?: number;
    totalTime?: number;
    extra?: any;
    dueTime?: number;
    activityId?: string;
    interval?: number;
}

export class ScheduledActivity implements IScheduledActivity {
    id: string;
    // date: string;
    content: string;
    status: ActivityStatus;
    latestStartTime?: string;
    latestCompleted?: number;
    timed?: boolean;
    startTime?: number;
    lastStartTime?: number;
    stopTime?: number;
    totalTime?: number;
    extra?: any;
    dueTime?: number;
    activityId?: string;
    interval?: number;

    constructor(props: IScheduledActivity) {
        Object.assign(this, props);
        clearObjectOfUndefines(this);
    }

    timeRanOut() {
        if (this.dueTime === 0) {
            return false;
        }
        const ranOut = ((timestampHasPassed(this.dueTime) && (this.status !== ActivityStatus.DIDNT_DO && this.status !== ActivityStatus.IN_PROGRESS && this.status !== ActivityStatus.DONE)))
        return ranOut;
    }

    getActivity(activities: Activity[]) {
        return activities.find(a => a.id === this.getActivityId());
    }

    expired() {
        this.status = ActivityStatus.DIDNT_DO;
        this.dueTime = -1;
    }

    play() {
        const time = getNow();
        if (!this.lastStartTime) {
            this.startTime = time;
        }
        this.lastStartTime = time;
        this.timed = true;
        this.status = ActivityStatus.IN_PROGRESS;
    }

    pause() {
        const currentTime = new Date().getTime();
        this.status = ActivityStatus.PAUSED;
        const timeSinceLastStart = currentTime - this.lastStartTime
        this.totalTime = (this?.totalTime || 0) + timeSinceLastStart;
    }

    finish(): ScheduledActivity | null {
        const currentTime = getNow();
        this.stopTime = currentTime;
        this.status = ActivityStatus.DONE;
        const timeSinceLastStart = currentTime - this.lastStartTime;
        this.totalTime = this.timed ? ((this.totalTime || 0) + timeSinceLastStart) : 0;
        if (isNaN(this.totalTime)) {
            this.totalTime = 0;
        }
        if (this.interval) {
            const newStartTime = this.stopTime + this.interval
            const newScheduledActivity: ScheduledActivity = new ScheduledActivity({
                id: generateId(),
                content: this.content,
                status: ActivityStatus.NOT_STARTED,
                activityId: this.activityId,
                interval: this.interval,
                startTime: newStartTime
            });
            return newScheduledActivity;
        }
        return null;
        // const activity: Activity = dataContext.activities.getActivity(this.activityId);
        // if (activity) {
        //     const interval = activity.getInterval();
        //         if(interval > 0){
        //             const dueTime = fromTime + interval;
        //             dataContext.scheduledActivities.createScheduledActivity(this.content, dataContext, true, activity.id, undefined, dueTime)
        //         }
        // }

        // dataContext.scheduledActivities.updateScheduledActivity(this, dataContext, this.startTime);
        // this.updateFirebase(user);
    }

    printTimeLeft(): string {
        if (this.startTime === 0) {
            return "";
        }
        // return this.status !== ActivityStatus.DIDNT_DO ? `${getTimeLeft(this?.dueTime)}` || undefined : undefined;
        const timePassed = this.startTime < getNow();
        return `${timePassed ? "-" : ""}${getTimeLeft(this.startTime)}`
    }

    pastDueTime(): boolean {
        return this?.dueTime ? this.dueTime < getNow() : false;
    }

    printDueTime(): string {
        if (!this.dueTime) {
            return "";
        }
        const timeString = convertTimestampToStringDateAndTime(this.dueTime);
        return timeString.slice(2, timeString.length);
    }

    printDueTimeTimeLeft(): string {
        if (!this.dueTime) {
            return "";
        }
        const timeLeft = getDuration(this.dueTime - getNow())
        return timeLeft;
    }

    isTimed() {
        return this.timed === true || this.totalTime ? true : false
    }

    getTotalRunTime(): number {
        if (this.status === ActivityStatus.PAUSED) {
            return this.totalTime;
        }
        const currentTime = getNow();
        const totalTime = (this.totalTime || 0) + (currentTime - this.lastStartTime || 0);
        return totalTime;
    }

    isPaused(): boolean {
        return this.status === ActivityStatus.PAUSED;
    }

    isInProgress(): boolean {
        return this.status === ActivityStatus.IN_PROGRESS;
    }

    notStarted(): boolean {
        return this.status === ActivityStatus.NOT_STARTED;
    }

    getTime(): string {
        const currentTotalTime = this.getTotalRunTime();
        return getDuration(currentTotalTime)
    }

    getTotalRunningTimeFormatted(): string {
        if (!this.totalTime) {
            return "";
        }
        return getDuration(this.totalTime)
    }

    counterIsActive() {
        return this &&
            this.timed === true &&
            this.status !== ActivityStatus.DIDNT_DO && this.status === ActivityStatus.IN_PROGRESS
    }

    getActivityId(): string {
        return this?.extra?.card?.extra?.activityId;
    }

    updateFirebase(user: string) {
        const path = getDataPath(user, data.SCHEDULED_ACTIVITY, this.id);
        const json = this.toJsonObject();
        clearObjectOfUndefines(json)
        writeData(path, json);
    }

    isFinished() {
        return this.status === ActivityStatus.DONE;
    }

    getStartTimeDate(): string {
        return convertTimestampToStringDate(this.startTime) || null;
    }

    getStopTimeDate(): string {
        return convertTimestampToStringDate(this.stopTime) || null;
    }

    deleteFirebase(user: string) {
        deleteData(getDataPath(user, data.SCHEDULED_ACTIVITY, this.id))
    }

    toString() {
        return `${this.content} - ${this.printDueTime()}`
    }

    toJsonObject(): IScheduledActivity {
        return {
            id: this.id,
            latestStartTime: this.latestStartTime,
            latestCompleted: this.latestCompleted,
            content: this.content,
            status: this.status,
            timed: this.timed,
            startTime: this.startTime,
            lastStartTime: this.lastStartTime,
            stopTime: this.stopTime,
            totalTime: this.totalTime,
            extra: this.extra,
            dueTime: this.dueTime,
            activityId: this.activityId,
            interval: this.interval
        }
    }

    addScheduledActivity(dataContext: TData, calendarData: CalendarData, user) {
        dataContext.calendarData.setCalendarDate(calendarData, true);
        calendarData.updateFirebase(user);
        dataContext.scheduledActivities.updateScheduledActivity(this, dataContext);
        this.updateFirebase(user);
    }

}