import * as React from 'react'
import { ITag } from '../components/tags/Tags';
import { readData, writeData } from '../utils/Firebase';
import { data, getDataPath } from '../utils/constants';



export const useTags = (user) => {
    const tagsPath = getDataPath(user, data.TAGS);
    const [tags, setTags] = React.useState<{ [key: string]: ITag }>({});
    
    React.useEffect(() => {
        const getTags = async () => {
            console.log(tagsPath)
            const tagsData = await readData(tagsPath);
            if(tagsData){
                setTags(tagsData);
            }
        }
        if (user) {
            getTags();
        }
    }, [user])

    const addTag = (tag: ITag) => {
        tags[tag.id] = tag;
        setTags({ ...tags })
        writeData(`${tagsPath}/${tag.id}`, tag)
    }

    const removeTag = (tag: ITag) => {
        delete tags[tag.id]
        setTags({ ...tags })
        writeData(tagsPath, tags)
    }

    // const getTag = async (tagId: string): Promise<ITag> => {
    //     if (tagId in tags) {
    //         return tags[tagId];
    //     }
    //     const tagsData = await readData(firebaseModulePaths.GET_TAGS_MODULE_PATH(user, tagId))
    //     return tagsData;
    // }

    const getTagsByName = (tagName: string): ITag[] => {
        const tagsByName = Object.values(tags).filter(t => t.name === tagName)
        return tagsByName;
    }

    // const getPrioedTags = async (dataContext: TData): Promise<{name: string, occurrences: number}[]> => {
    //     const logs: IEventLog[] = await dataContext.calendarData.getEventLogs(subDaysStringDate(getToday(), 30), addDaysStringDate(getToday(), 30));
    //     const tagLogs = logs.filter(log => log.type === LogTypeEnums.TAG);
    //     const tagOccurrences = tagLogs.reduce((acc, log) => {
    //         if(!acc.find(t => t.name === log.content)) {
    //             acc.push({name: log.content, occurrences: 1})
    //         } else {
    //             acc.find(t => t.name === log.content).occurrences += 1;
    //         }
    //         return acc;
    //     }, []);
    //     return tagOccurrences.sort((a, b) => b.occurrences - a.occurrences);
    // }

    return { tags, setTags, addTag, removeTag,/*  , removeTag, getTag, */ getTagsByName };
}