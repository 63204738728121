import { addDaysStringDate, convertStringDateToTimestamp } from './../../utils/TimeUtils';
import { CalendarData } from "../../components/calendar/CalendarData";
import { Activity } from "../../data/activity/Activity";
import { Rule } from "../../data/activity/Rule/Rule";
import { getToday } from "../../utils/TimeUtils";
import { getDate, getDay, getWeek } from 'date-fns';
import { isLastDayOfMonth } from 'date-fns';

export class SchedulingService {
    inited: boolean

    static ruleIsValidForDate(rule: Rule, calendarData: CalendarData, activity?: Activity, allDates?: CalendarData[]) {
        const validationFunction = SchedulingService.getRuleCode(rule)
        if(!validationFunction){
            return true;
        }
        return validationFunction(calendarData, rule.funcParams, activity, allDates);
    }

    static allRulesAreValidForDate(rules: Rule[], date: CalendarData, activity?: Activity, allDates?: CalendarData[]) {
        return rules?.every(rule => {
            const isValid = SchedulingService.ruleIsValidForDate(rule, date, activity, allDates)
            return isValid;
        });
    }

    static getDatesValidForActivity(activity: Activity, dates: CalendarData[]) {
        return activity.rules?.map(rule => {
            const validDates = dates.filter(date => SchedulingService.allRulesAreValidForDate(rule.ruleSet, date, activity, dates));
            return { id: rule.name, validDates: validDates?.map(v => v.date) || [], name: rule.name, startTime: rule?.ruleSet?.find(r => r.func === "startTime")?.funcParams.startTime || undefined }   
        }
        )
    }

    static getRuleCode = (rule: Rule): Function => {
        return SchedulingService[rule.func];
    }

    static isToday = (calendarData: CalendarData, ...params) => {
        return calendarData.date === getToday();
    }

    static isTomorrow = (calendarData: CalendarData) => {
        return calendarData.date === addDaysStringDate(getToday(), 1);
    }

    static oddEvenWeek = (calendarData: CalendarData, params) => {

        const isEvenWeek = (calendarData: CalendarData, params) => {
            if (params.oddEven === "even") {
                const timestamp = convertStringDateToTimestamp(calendarData.date);
                const weekNumber = getWeek(timestamp, { weekStartsOn: 1 });
                return weekNumber % 2 === 0;
            }
            return false;
        }

        const isOddWeek = (calendarData: CalendarData, params) => {
            if (params.oddEven === "odd") {
                const timestamp = convertStringDateToTimestamp(calendarData.date);
                const weekNumber = getWeek(timestamp, { weekStartsOn: 1 });
                return weekNumber % 2 === 1;
            }
            return false;
        }

        if ((params.oddEven === "even" && isEvenWeek(calendarData, params)) || (params.oddEven === "odd" && isOddWeek(calendarData, params))) {
            return true;
        }
        return false;
    }

    static isDateInMonth = (calendarData: CalendarData, params: { day }) => {
        const currentCalendarDate = convertStringDateToTimestamp(calendarData.date);
        const currentDay = getDate(currentCalendarDate);
        if (params.day.includes("Sista") && isLastDayOfMonth(currentCalendarDate)) {
            return true;
        }
        return params.day.includes(currentDay);
    }

    //0 = söndag, 1 = måndag, 2 = tisdag, 3 = onsdag, 4 = torsdag, 5 = fredag, 6 = lördag
    static isWeekday = (calendarData: CalendarData, params: { day: number[] }) => {
        const currentCalendarDate = convertStringDateToTimestamp(calendarData.date);
        let currentDay = getDay(currentCalendarDate) - 1;
        if (currentDay === -1) {
            currentDay = 6;
        }
        return params.day?.includes(currentDay);
    }

    static enoughDaysSinceLastComplete = (calendarData: CalendarData, params: { days: number }, activity: Activity, allDates: CalendarData[]) => {
        let latestCompleted;
        allDates.forEach((date: CalendarData) => {
            if (date.scheduleHasActivity(activity.id)) {
                if (!latestCompleted || latestCompleted < date.date) {
                    latestCompleted = date.date;
                }
            }
        }
        )
        const daysSinceLastComplete = parseInt(calendarData.date) - parseInt(latestCompleted);
        return daysSinceLastComplete >= params.days;
    }

    static enoughDaysToNextOfType = (calendarData: CalendarData, params: { days: number, mockToday?: string }, activity: Activity, allDates: CalendarData[]) => {
        let nextDate;
        const today = params.mockToday || getToday();
        const futureDates = allDates.filter(date => date.date > today);
        futureDates.forEach((date: CalendarData) => {
            if (date.scheduleHasActivity(activity.id)) {
                if (!nextDate || date.date < nextDate) {
                    nextDate = date.date;
                }
            }

        })
        const daysTilNext = parseInt(nextDate) - parseInt(calendarData.date);
        return daysTilNext >= params.days;
    }
    //autoschedulera antal dagar sen senast slutförd


}