import * as React from 'react';
import { AppContext } from '../../../app/AppContext';
import "./PageLoader.scss";
import { MarginRight } from '../../layout/margin/MarginRight';
import { DataContext } from '../../../app/DataContext';
import { ReactGridModule } from '../../../module/modules/ReactGridModule/ReactGridModule';
import { MarginBottom } from '../../layout/margin/MarginBottom';
import { removeModuleFromRootLayout } from '../../../app/utils';
import { ModuleRelations } from '../../../module/modules/ActivityTableModule/ModuleRelations';
import { Button } from '../../button/Button';
import { resetFirebase } from '../../../debugUtils/firebaseDebugUtils';
import { migrated_logs } from '../../../migration/ready_to_migrate_logs';
import { CalendarData } from '../../calendar/CalendarData';
import { generateId } from '../../../utils/Utils';
import { getNow } from '../../../utils/TimeUtils';
import { calendar_dates } from '../../../migration/calendar_dates';
import { readData, writeData } from '../../../utils/Firebase';
import { getDatesBetween } from '../../../utils/FirebaseCalendarUtils';
import { calendarDates } from '../../../migration/calendar_dates_from_firebase';
import { firebaseModulePaths } from '../../../utils/constants';

const PageLoader = () => {
    const dataContext = React.useContext(DataContext);
    const appContext = React.useContext(AppContext);
    const [startModule, setStartModule] = React.useState<ReactGridModule>();

    React.useEffect(() => {
        if (startModule && appContext) {
            startModule.currentBreakpoint = appContext.currentBreakpoint;
            startModule.forceRender();
        }
    }, [appContext?.currentBreakpoint])

    React.useEffect(() => {
        initLayout();
        initScheduling();
    }, [])

    const initLayout = async () => {
        const rootModule: ReactGridModule = await dataContext.modules.getModule("LayoutStartPoint", dataContext, appContext, true) as ReactGridModule;
        if (rootModule) {
            const ids = rootModule.layout.getGridIds();
            const modules = await dataContext.modules.getModules(ids, dataContext, appContext, true);
            modules.forEach(m => {
                m.onDelete = (self: ModuleRelations) => {
                    removeModuleFromRootLayout(dataContext, appContext, self, false);
                    dataContext.modules.deleteModule(self);
                    self.deleteFromFirebase();
                }
                if ("parentModule" in m) {
                    m.parentModule = rootModule;
                }
            })
            rootModule.childModules = modules;
            rootModule.currentBreakpoint = appContext.currentBreakpoint;
            setStartModule(rootModule);
        }
    }

    const initScheduling = async () => {

    }

    // console.log(dataContext.calendarData?.calendarData[0]?.date, dataContext.calendarData?.calendarData[0]?.schedule?.length);
    // console.log(dataContext.calendarData?.calendarData[1]?.date, dataContext.calendarData?.calendarData[1]?.schedule?.length);

    return (
        <div className="page-container" data-testid="page-container">
            {/* <Button label={'Rensa dubletter i kalendern'} onClick={async () => {
                for (const calendarDate of calendarDates) {
                    if (calendarDate.data?.logs) {
                        // console.log(calendarDate.data.logs);
                        const uniqueLogs = Array.from(new Set(calendarDate.data.logs.map(log => log.content)))
                        .map(content => calendarDate.data.logs.find(log => log.content === content));
                        writeData(`${firebaseModulePaths.GET_CALENDAR_DATE_MODULE_PATH(appContext.user, calendarDate.date)}/data/logs`, uniqueLogs);
                    }
                    // const calendarData = await dataContext.calendarData.getCalendarDate(date, true, true, dataContext);
                }
            }}
            />
            <Button label={'Migrera loggar'} onClick={async () => {

                const addLog = async (date: string, title, text) => {
                    const calendarData = await dataContext.calendarData.getCalendarDate(date, true, true, dataContext);
                    if (!calendarData.data) {
                        calendarData.data = { icons: [], logs: [] };
                    }
                    if (!calendarData.data.logs.includes(text)) {
                        calendarData?.data?.logs?.push({ id: generateId(), content: text, created: getNow(), title })
                        calendarData.updateFirebase(appContext.user);
                        console.log('added log', date, title, text);
                    }
                    else {
                        console.log('log already exists', date, title, text);
                    }
                }

                for (const log of Object.entries(migrated_logs)) {
                    const [key, value] = log;
                    for (const log of value) {

                        await addLog(key, "", log);
                    };
                }
                console.log('done');
            }} />
            <Button
                onClick={async () => {
                    await resetFirebase(appContext.user);
                }}
                label={'Återställ firebase'}
            />
            <MarginRight /> */}
            <MarginBottom />
            {startModule && <div>{startModule.renderModule()}</div>}
        </div>
    )
}

export { PageLoader }