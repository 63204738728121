import * as React from 'react'
import { Input } from '../../input/Input';
import { Column } from '../../layout/column/Column';
import { BaseModule } from '../../../module/modules/BaseModule';
import "./ModulePicker.scss"
import { MarginBottom } from '../../layout/margin/MarginBottom';
import { DataContext } from '../../../app/DataContext';
import { AppContext } from '../../../app/AppContext';

const ModulePicker = ({ modules: modulesProp, onPick, customRow }: { modules: BaseModule[], onPick: (module: BaseModule) => void, customRow?: (module: BaseModule) => JSX.Element }) => {
    const dataContext = React.useContext(DataContext);
    const appContext = React.useContext(AppContext);
    const [modules, setModules] = React.useState(modulesProp || []);
    const [displayedModules, setDisplayedModules] = React.useState([]);
    // const [selectedModules, setSelectedModules] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (!modules?.length) {
            dataContext.modules.getAllModules(dataContext, appContext, true).then(modules => {
                setModules(modules);
            })
        }
    }, [])

    React.useEffect(() => {
        if (modules?.length) {
            setDisplayedModules(modules)
        }
    }, [modules])

    const handlePick = (module: BaseModule) => {
        onPick(module);
    }

    console.log(modules);
    console.log(displayedModules);
    return (
        <div className="module-picker">
            <div>ModulePicker</div>
            <div>number of modules: {modules.length} </div>
            <Input
                debounce={500}
                onChange={(searchQuery) => {
                    if (!searchQuery) {
                        setDisplayedModules(modules);
                    }
                    else {
                        const filteredModules = modules.filter(mod => mod?.name?.toLowerCase().includes(searchQuery.toLowerCase()));
                        setDisplayedModules(filteredModules);
                    }
                }} />
            <MarginBottom />
            <Column alignLeft>
                {/* {selectedModule && selectedModule.name} */}
                {/* !selectedModule && */ displayedModules.map((mod, key) => {
                    return <div
                        className={`item-row`}
                        key={key}
                        onClick={() => handlePick(mod)}>
                        {customRow ? customRow(mod) : mod.name}
                    </div>
                })}
            </Column>
        </div>
    )
}

export default ModulePicker