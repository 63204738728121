import * as React from 'react'
import { Rule } from '../../data/activity/Rule/Rule';
import { weekdays } from '../../utils/constants';
import { generateId } from '../../utils/Utils';
import { MarginBottom } from '../layout/margin/MarginBottom';
import { Row } from '../layout/row/Row';
import { Checkbox } from '../checkbox/Checkbox';
import { Button } from '../button/Button';
import InputConfirm from '../input_and_confirm/InputConfirm';
import { TimePicker } from '../pickers/time_picker/TimePicker';

const ActivityRulesCreator = ({ selectedRule, onCreate }: {
    // activity: Activity,
    // onIntervalChange: (interval: number) => void,
    selectedRule?: { id: string, name: string, ruleSet: Rule[] },
    onCreate: (newRule: { id: string, name: string, ruleSet: Rule[] }) => void
}) => {

    const [newRule, setNewRule] = React.useState<{ id: string, name: string, ruleSet: Rule[] }>(selectedRule || { id: generateId(), name: "", ruleSet: [] });

    const renderDayInMonth = () => <div>
        <b>Välj dagar i månaden</b>
        <Row>
            {[...Array.from({ length: 31 }, (_, idx) => idx + 1), "Sista"].map((day) => (
                <React.Fragment key={day}>
                    <Checkbox
                        labelAlignment='top'
                        checked={newRule.ruleSet?.some((rule: Rule) => rule.func === "isDateInMonth" && rule.funcParams.day.includes(day)) || false}
                        label={day.toString()}
                        onChange={(checked) => {
                            let dateInMonthRule = newRule.ruleSet?.find((rule: Rule) => rule.func === "isDateInMonth");
                            if (!dateInMonthRule) {
                                dateInMonthRule = new Rule({ id: generateId(), func: "isDateInMonth", funcParams: { day: [] } });
                                if(!newRule.ruleSet) newRule.ruleSet = [];
                                newRule.ruleSet.push(dateInMonthRule);
                            }

                            if (!dateInMonthRule.funcParams.day) {
                                dateInMonthRule.funcParams.day = [];
                            }

                            if (checked) {
                                if (!dateInMonthRule.funcParams.day.includes(day)) {
                                    dateInMonthRule.funcParams.day.push(day);
                                }
                            } else {
                                dateInMonthRule.funcParams.day = dateInMonthRule.funcParams.day.filter((d: number) => d !== day);
                            }
                        }}
                    />
                </React.Fragment>
            ))}
        </Row>
        <MarginBottom />
    </div>

    const oddEvenWeek = () => <div>
        <b>Välj jämn/ojämn vecka</b>
        <Row>
            <Checkbox
                checked={newRule.ruleSet?.some(rule => rule.func === "oddEvenWeek" && rule.funcParams.oddEven === "even") || false}
                label="Jämn vecka"
                onChange={(checked) => {
                    if (!newRule.ruleSet) {
                        newRule.ruleSet = []
                    }
                    if (checked) {
                        newRule.ruleSet.push(new Rule({ id: generateId(), func: "oddEvenWeek", funcParams: { oddEven: "even" } }));
                    } else {
                        newRule.ruleSet = newRule.ruleSet.filter(rule => rule.func !== "oddEvenWeek");
                    }
                }}
            />
            <Checkbox
                checked={newRule.ruleSet?.some(rule => rule.func === "oddEvenWeek" && rule.funcParams.oddEven === "odd") || false}
                label="Ojämn vecka"
                onChange={(checked) => {
                    if (!newRule.ruleSet) {
                        newRule.ruleSet = []
                    }
                    if (checked) {
                        newRule.ruleSet.push(new Rule({ id: generateId(), func: "oddEvenWeek", funcParams: { oddEven: "odd" } }));
                    } else {
                        newRule.ruleSet = newRule.ruleSet.filter(rule => rule.func !== "oddEvenWeek");
                    }
                }}
            />
        </Row>
        <MarginBottom />
    </div>

    const renderDayInWeek = () => <div>
        <b>Välj veckodagar</b>
        <Row>
            {weekdays.map((day, idx) => (
                <React.Fragment key={day}>
                    <Checkbox
                        checked={newRule.ruleSet?.some(rule => rule.func === "isWeekday" && rule.funcParams.day.includes(idx)) || false}
                        label={day}
                        onChange={(checked) => {
                            let weekdayRule = newRule.ruleSet?.find(rule => rule.func === "isWeekday");
                            if (!weekdayRule) {
                                weekdayRule = new Rule({ id: generateId(), func: "isWeekday", funcParams: { day: [] } })
                                if(!newRule.ruleSet) newRule.ruleSet = [];
                                newRule.ruleSet.push(weekdayRule);
                            }
                            if (checked) {
                                if (!weekdayRule.funcParams.day.includes(idx)) {
                                    weekdayRule.funcParams.day.push(idx);
                                }
                            }
                            else if (!checked) {
                                weekdayRule.funcParams.day = weekdayRule.funcParams.day.filter(day => day !== idx);
                            }
                        }}
                    />
                </React.Fragment>
            ))}
        </Row>
        <MarginBottom />
    </div>

    const renderStarttime = () => {
        const defaultTime = newRule.ruleSet?.find(rule => rule.func === "startTime")?.funcParams.startTime || 0
        return <TimePicker
            defaultTime={defaultTime}
            onChange={time => {
                console.log(time);
                // const stringTime = covertTimestampToStringTime(time.getTime());
                if (defaultTime === 0) {
                    newRule.ruleSet.push(new Rule({ id: generateId(), func: "startTime", funcParams: { startTime: time.getTime() } }));
                }
                else{
                    newRule.ruleSet.find(rule => rule.func === "startTime").funcParams.startTime = time.getTime();
                }
                // activity.defaultStartTime = stringTime;
            }} />
    }

    return (
        <div>
            <InputConfirm boldLabel defaultValue={newRule.name} label='Namn' onConfirm={name => {
                setNewRule({ ...newRule, name });
            }} />
            <MarginBottom />
            {renderDayInMonth()}
            {oddEvenWeek()}
            {renderDayInWeek()}
            {renderStarttime()}
            {/* {renderInterval()} */}
            <MarginBottom />
            <MarginBottom />
            <Row>
                <Button label="Klar" onClick={() => {
                    onCreate(newRule);
                }}
                />
            </Row>
        </div>
    )
}

export { ActivityRulesCreator }