import * as React from 'react';
import { Button } from '../../../components/button/Button';
import { DateTimepicker } from '../../../components/pickers/datetime_picker/DateTimepicker';
import { ScheduledActivity } from '../../../components/activity_generator/ScheduledActivity';
import { addHours } from 'date-fns';
import Modal from '../../../components/modal/Modal';
import { convertTimestampToStringDateAndTime } from '../../../utils/TimeUtils';
import { Row } from '../../../components/layout/row/Row';
import { MarginRight } from '../../../components/layout/margin/MarginRight';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import { ActivityStatus } from '../../../utils/constants';

const ScheduledActivityViewer = ({ scheduledActivity, onDeleteScheduledActivity, onUpdateScheduledActivity, dataTestid, hideHeader, defaultDate }: { scheduledActivity: ScheduledActivity, onDeleteScheduledActivity: Function, onUpdateScheduledActivity: Function, dataTestid?: string, hideHeader?: boolean, defaultDate?: Date }) => {
    const [showModal, setShowModal] = React.useState(false);
    const getDefaultDate = (): Date => scheduledActivity.startTime && new Date(scheduledActivity.startTime) || defaultDate || addHours(new Date(), 1);
    return (
        <Row dataTestid={dataTestid}>
            {!hideHeader && <h1>{scheduledActivity?.content}</h1>}
            <Button dataTestid='planned-starttime' label={'Planerad starttid för aktiviteten'} onClick={() => { setShowModal(true) }} />
            <MarginRight />
            <Button label={'Radera aktivitet'} onClick={() => {
                onDeleteScheduledActivity();
                setShowModal(false);
            }} />
            <MarginRight />
            <Checkbox label='Klar' checked={scheduledActivity.status === ActivityStatus.DONE} onChange={() => {

            }}
            />
            <MarginRight />
            {scheduledActivity.startTime > 0 && <div>Startas senast: {convertTimestampToStringDateAndTime(scheduledActivity.startTime)}</div>}
            <Modal showModal={showModal} setShowModal={setShowModal}>
                <>
                    {
                        <DateTimepicker
                            defaultTime={getDefaultDate()}
                            separateTimeAndDate
                            onChange={newTime => {
                                // setPreviousDate(convertTimestampToStringDate(scheduledActivity.startTime));
                                scheduledActivity.startTime = newTime.getTime()
                            }}
                        />
                    }
                    <Button dataTestid="confirm-button" label={'Bekräfta'} onClick={() => {
                        // const previousDate = previous > 0 ? convertTimestampToStringDate(previous) : 0;
                        if (!scheduledActivity.startTime) {
                            scheduledActivity.startTime = getDefaultDate().getTime();
                        }
                        onUpdateScheduledActivity(scheduledActivity);
                        setShowModal(false);
                    }} />
                </>
            </Modal>
        </Row>
    );
};

export default ScheduledActivityViewer;