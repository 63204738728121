import { IModuleRelationsProps } from './../../module/modules/ActivityTableModule/ModuleRelations';
import { ICardModuleProps } from './../../module/modules/CardModule/CardModule';
import { ScheduledActivity } from "../../components/activity_generator/ScheduledActivity";
import { ILayout, Layout } from "../../module/Layout";
import { ActivityTableModule, IActivityTableModuleProps } from "../../module/modules/ActivityTableModule/ActivityTableModule";
import { BaseModule, IBaseModule } from "../../module/modules/BaseModule";
import { IReactGridModuleProps, ReactGridModule } from "../../module/modules/ReactGridModule/ReactGridModule";
// import { TodoBoardModule } from "../../module/modules/TodoBoardModule/TodoBoardModule";
import { ActivityStatus, breakpointTypes, modules } from "../../utils/constants";
import { generateId } from "../../utils/Utils";
import { Activity } from "../activity/Activity";
import { ICalendarEventsModule } from '../../module/modules/CalendarEventsModule/CalendarEventsModule';
import { addDaysStringDate, getNow, getStringDatesBetween, getToday } from '../../utils/TimeUtils';
import { CalendarModule } from '../../module/modules/CalendarModule/CalendarModule';
import { CalendarData } from '../../components/calendar/CalendarData';
import { ScheduledActivityCardModule } from '../../module/modules/ScheduledActivityCardModule/ScheduledActivityCardModule';
import { GridItem } from '../../module/modules/ReactGridModule/GridItem';
import { TodoBoardModule } from '../../module/modules/TodoBoardModule/TodoBoardModule';

export const userMock = "testuserid";
export const rootLayoutIdMock = "testRootLayoutId";

export const activitiesMock = [
    new Activity({ name: "Hushåll", id: "hushall", created: getNow() }),
    new Activity({ name: "Fritid", id: "fritid", created: getNow() }),
    new Activity({ name: "Träning", id: "traning", created: getNow() }),
]

export const gridMock = (gridProps?: Partial<GridItem>): GridItem => (new GridItem({ ...{ x: 0, y: 0, w: 6, h: 20, i: "0" }, ...gridProps }, "rootLayoutIdMock"));

export const layoutMock = (gridIds: string[], gridMocks?: Partial<GridItem>[]): Layout => {
    const grids = gridIds.reduce((previous, id, currentIndex) => {
        const gridOverride = gridMocks?.length === gridIds.length ? gridMocks[currentIndex] : undefined;
        const gridObject = { i: id, ...gridOverride };
        // console.log(gridObject);
        previous[id] = gridMock(gridObject);
        // console.log(previous[id])
        return previous
    }, {});
    // console.log(grids);
    return new Layout({ id: "testlayoutid", [breakpointTypes.SM]: grids, [breakpointTypes.LG]: grids });
}

export const IGridLayoutMock = (grids: GridItem[]): ILayout => ({ id: "testlayoutid", [breakpointTypes.SM]: {}, [breakpointTypes.LG]: {} });

// export const cardMock = (cardBaseProps?: Partial<IBaseModule>, cardProps?: Partial<ICardModuleProps>, relationProps?: Partial<IModuleRelationsProps>): CardModule => new CardModule(
//     baseProps(modules.CARD, cardBaseProps),
//     {
//         containerOnDragStop: (baseModule: BaseModule) => { },
//         ...cardProps
//     },
//     {
//         parentModule: null,
//         onRemoveChildModule: (removedModule?: BaseModule, yourself?: BaseModule) => { },
//         childModules: [], layout: null, onCreateChildModule: (createdModule: BaseModule, yourSelf: BaseModule) => {
//             console.log("onCreateChildModule", createdModule, yourSelf)
//         }, ...relationProps
//     }
// );

export const reactGridLayoutMockNoModules = (reactGridProps?: Partial<IBaseModule>, layoutItemsIds?: string[], gridMocks?: Partial<GridItem>[]): ReactGridModule => {
    const reactGridModuleProps: IReactGridModuleProps = {
        onDragStart: (newGrid: GridItem) => { console.log("Drag Start", newGrid) },
        onDragStop: (reactGridModule: ReactGridModule) => { console.log("Drag Stop", reactGridModule) },
        onDragOver: (e: React.DragEvent<HTMLDivElement>) => { console.log("Drag Over", e) },
        onResizeStop: (reactGridModule: ReactGridModule) => {
            // reactGridModule.layout.updateFirebaseData("", reactGridModule.id);
        },
        currentBreakpoint: breakpointTypes.LG,
        // onRemoveModule: (module: BaseModule) => {/*  console.log("Remove Module", module) */ },
        // layout: layoutMock(layoutItemsIds || [], gridMocks || []),
    }
    const newGridModule: ReactGridModule = new ReactGridModule(
        // baseProps(modules.GRID_LAYOUT, reactGridProps),
        BaseModule.getDefaultBaseModuleProps("Grid", modules.GRID_LAYOUT),
        {
            newChildModule: async (params?: any) => { return null; },
            parentModule: null, childModules: [],
            onRemoveChildModule: (removedModule?: BaseModule, yourself?: BaseModule) => { },
            layout: layoutItemsIds && gridMocks ? layoutMock(layoutItemsIds, gridMocks) : null, onCreateChildModule: (createdModule: BaseModule, yourSelf: BaseModule) => {
                console.log("onCreateChildModule", createdModule, yourSelf)
            }
        },
        reactGridModuleProps
    );
    return newGridModule;
}


// export const reactGridLayoutStandardModules = (reactGridModuleProps?: Partial<IReactGridModuleProps>): ReactGridModule => {
//     const reactGridProps = { id: "testRootLayoutId", name: "Mainlayout" }
//     const layoutItemsIds = ["upcomingEventsModule", "todoBoardModule", "activityTableModule", "calendarModule"]
//     const gridMocks = [{ w: 4, h: 20, x: 0 }, { w: 4, x: 4, h: 20 }, { w: 4, x: 8, h: 20 }, { w: 12, x: 0, y: 20, h: 30 }]

//     // const calendarEventsModule: CalendarEventsModule = getCalendarEventsModule({ id: "upcomingEventsModule" })
//     // const todoBoardModule: TodoBoardModule = createTodoBoardModule({ id: "todoBoardModule" })
//     const todoBoardModule: TodoBoardModule = TodoBoardModule.createTodoBoardModule({ id: "todoBoardModule" })
//     const activityTableModule: ActivityTableModule = createActivityTableModule({ id: "activityTableModule" });
//     const calendarModule: CalendarModule = createCalendarModuleMock({ id: "calendarModule" });

//     // const mods: BaseModule[] = [calendarEventsModule, todoBoardModule, activityTableModule, calendarModule]
//     // const baseMod: IBaseModule = baseProps(modules.GRID_LAYOUT, reactGridProps);
//     const baseMod = BaseModule.getDefaultBaseModuleProps("Grid", modules.GRID_LAYOUT)
//     const reactGridModulePrps: IReactGridModuleProps = {
//         onDragStart: (newGrid: GridItem) => { console.log("Drag Start", newGrid) },
//         onDragStop: (reactGridModule: ReactGridModule) => { console.log("Drag Stop", reactGridModule) },
//         onDragOver: (e: React.DragEvent<HTMLDivElement>) => { console.log("Drag Over", e) },
//         onResizeStop: (reactGridModule: ReactGridModule) => {
//             // reactGridModule.layout.updateFirebaseData("", reactGridModule.id);
//         },
//         // onRemoveModule: (module: BaseModule) => { console.log("Remove Module", module) },
//         // layout: layoutMock(layoutItemsIds, gridMocks),
//         // modules: mods,
//         currentBreakpoint: breakpointTypes.LG,
//         ...reactGridModuleProps
//     }
//     const newGridModule: ReactGridModule = new ReactGridModule(
//         baseMod,
//         {
//             parentModule: null,
//             childModules: mods,
//             onRemoveChildModule: (removedModule?: BaseModule, yourself?: BaseModule) => { },
//             layout: layoutMock(layoutItemsIds, gridMocks), onCreateChildModule: (createdModule: BaseModule, yourSelf: BaseModule) => {
//                 console.log("onCreateChildModule", createdModule, yourSelf)
//             }
//         },
//         reactGridModulePrps
//     );
//     return newGridModule;
// }

// export const getDefaultDatesData = (): TDates => {
//     const startDate = getToday();
//     const endDate = addDaysStringDate(startDate, 10);
//     const dates = getStringDatesBetween(startDate, endDate);
//     return dates.map(date => {
//         const newCalendarDate: CalendarData = new CalendarData({ id: generateId(), date, schedule: [] })
//         return { calendarData: newCalendarDate, schedule: [] };
//     })
// }

// export const getCalendarEventsModule = (overrideBaseProps?: Partial<BaseModule>, overrideCalendarEventsProps?: Partial<ICalendarEventsModule>) => new CalendarEventsModule(
//     // baseProps(modules.CALENDAR_EVENTS, { name: 'CalendarEventsModule', ...overrideBaseProps, noDataContextSync: true }),
//     BaseModule.getDefaultBaseModuleProps("CalendarEventsModule", modules.CALENDAR_EVENTS, {...overrideBaseProps, noDataContextSync: true}),
//     getCalendarEventsModuleMock(overrideCalendarEventsProps));

export const createActivityTableModule = (activityTableModuleBaseProps?: Partial<IBaseModule>, mockedActivityTableModuleProps?: Partial<IActivityTableModuleProps>): ActivityTableModule => {
    const activityTableModuleProps: IActivityTableModuleProps = {
        onCreateScheduledActivity: (scheduledActivity: ScheduledActivity) => { console.log("Create", scheduledActivity) },
        onDeleteScheduledActivity: (scheduledActivity: ScheduledActivity) => { console.log("Delete", scheduledActivity) },
        onFinishScheduledActivity: (scheduledActivity: ScheduledActivity) => { console.log("Finish", scheduledActivity) },
        onDeleteActivity: (activity: Activity) => { console.log("Delete", activity) },
        onCreateActivity: async (activity: Activity) => { console.log("Create", activity) },
        onUpdateActivity: (activity: Activity) => { console.log("Update", activity) },
        sendToTodoList: (activity: Activity) => { console.log("Send to todo list", activity) },
        activities: [],
        startDate: getToday(),
        endDate: addDaysStringDate(getToday(), 10),
        ...mockedActivityTableModuleProps
    }

    return new ActivityTableModule(
        // { ...baseProps(modules.ACTIVITY_TABLE), ...activityTableModuleBaseProps },
        BaseModule.getDefaultBaseModuleProps("ActivityTable", modules.ACTIVITY_TABLE, activityTableModuleBaseProps),
        activityTableModuleProps
    );
}

// export const createCardModule = (): CardModule => {
//     return new CardModule(
//         baseProps(modules.CARD, { name: "hej" }),
//         {
//             containerOnDragStop: (baseModule: BaseModule) => { },
//         },
//         {
//             parentModule: null,
//             childModules: [],
//             layout: null,
//             onRemoveChildModule: (removedModule?: BaseModule, yourself?: BaseModule) => { },
//             onCreateChildModule: (createdModule: BaseModule, yourSelf: BaseModule) => {
//                 console.log("onCreateChildModule", createdModule, yourSelf)
//             }
//         }
//     );
// }

// export const createCardModuleWithChild = (): CardModule => {
//     const cardModule = new CardModule(baseProps(modules.CARD, { name: "Card Content" }), {
//         containerOnDragStop: (baseModule: BaseModule) => { },
//     },
//         {
//             parentModule: null,
//             childModules: [],
//             layout: null,
//             onRemoveChildModule: (removedModule?: BaseModule, yourself?: BaseModule) => { },
//             onCreateChildModule: (createdModule: BaseModule, yourSelf: BaseModule) => {
//                 console.log("onCreateChildModule", createdModule, yourSelf)
//             }
//         })
//     // const newCardModule1 = cardModule.addChildCard("Child Card");
//     // newCardModule1.addChildCard("Child Card 2");
//     const childCard = cardMock({ name: "Child Card" }, undefined, { parentModule: cardModule, childModules: [], layout: null });
//     cardModule.addChildModule(childCard, childCard.getDefaultGrid())
//     return cardModule;
// }

// export const getCalendarEventsModuleMock = (overrideCalendarEventsProps?: Partial<ICalendarEventsModule>) => {
//     const test = getDefaultDatesData()
//     const calendarEventsProps: ICalendarEventsModule = {
//         dates: test,
//         startDate: "",
//         endDate: "",
//         activities: [],
//         onFinishScheduledActivity: (scheduledActivity: ScheduledActivityCardModule) => { },
//         updateCalendarData: (calendarData: CalendarData) => { calendarData.updateFirebase(userMock) },
//         onDeleteScheduledActivity: (scheduledActivity: ScheduledActivityCardModule) => { },
//         onTemporaryScheduledActivityCreated: (newTemporaryScheduledActivity: ScheduledActivityCardModule, calendarData: CalendarData) => {
//             // dataContext.scheduledActivities.addScheduledActivity(newTemporaryScheduledActivity);
//             // dataContext.calendarData.setCalendarDate(calendarData, true);

//             // newTemporaryScheduledActivity.updateFirebase(user);
//             // calendarData.updateFirebase(user);
//             // console.log(firebaseModulePaths.GET_CALENDAR_DATE_MODULE_PATH(user, calendarData.date));
//             // console.log(calendarData);
//             // writeData(firebaseModulePaths.GET_CALENDAR_DATE_MODULE_PATH(user, calendarData.date), calendarData);
//         },
//         onScheduledActivityUpdate: (scheduledActivity: ScheduledActivityCardModule) => { },
//         onScheduledActivityClick: (activity: Activity) => { console.log("Scheduled Activity Click", activity) },
//         ...overrideCalendarEventsProps
//     }
//     return calendarEventsProps;
// }

// export const createUpcomingModule = (reactGridProps?: Partial<IBaseModule>) => {
//     return new CalendarEventsModule(
//         // { ...baseProps(modules.CALENDAR_EVENTS), ...reactGridProps },
//         BaseModule.getDefaultBaseModuleProps("CalendarEvents", modules.CALENDAR_EVENTS, reactGridProps),
//         getCalendarEventsModuleMock()
//     );
// }

// export const allModulesMock = (customModules?: BaseModule[]): { [key: string]: BaseModule } => (customModules?.length ? customModules.reduce((previous, module: BaseModule) => { previous[module.id] = module; return previous }, {}) : { ["0"]: cardMock({ id: "0" }) })

export const createCalendarModuleMock = (overrideBaseProps?: Partial<BaseModule>) => new CalendarModule({ id: "calendarModule", type: modules.CALENDAR, user: userMock, name: "CalendarModule", ...overrideBaseProps, onDelete: (self) => { console.log(self) } })

// export const appMockProps = {
//     appContextDefaults: {
//         rootLayoutId: rootLayoutIdMock,
//         user: "testuserid"
//     },
//     dataContextDefaults: {
//         modules: {
//             allModules:
//             {
//                 "testRootLayoutId": reactGridLayoutMock(
//                     { id: "testRootLayoutId", name: "Mainlayout" },
//                     ["upcomingEventsModule", "todoBoardModule", "activityTableModule", "calendarModule"],
//                     [{ w: 4, h: 20, x: 0 }, { w: 4, x: 4, h: 20 }, { w: 4, x: 8, h: 20 }, { w: 12, x: 0, y: 20, h: 25 }]
//                 ),
//                 "todoBoardModule": createTodoBoardModule({ id: "todoBoardModule", name: "TodoBoardModule" }),
//                 "upcomingEventsModule": createUpcomingModule({ id: "upcomingEventsModule", name: "UpcomingEventsModule" }),
//                 "activityTableModule": createActivityTableModule({ id: "activityTableModule", name: "ActivityTableModule" }, {
//                     initialData: [
//                         {
//                             activity: new Activity({ name: "TestActivity", created: getNow(), id: generateId() }),
//                             scheduledActivities: []
//                         }
//                     ]
//                 }),
//                 "calendarModule": createCalendarModuleMock(),
//             }
//         }
//     },
// }

// export const ReactGridMockProps = {
//     appContextDefaults: {
//         rootLayoutId: rootLayoutIdMock,
//     },
//     dataContextDefaults: {
//         modules: {
//             allModules:
//             {
//                 "testRootLayoutId": reactGridLayoutMockNoModules({ id: "testRootLayoutId", name: "Mainlayout" }, ["card1", "card2"]),
//                 "card1": cardMock({ id: "card1", name: "Card1" }),
//                 "card2": cardMock({ id: "card2", name: "Card2" })
//             }
//         }

//     },
//     mockUser: userMock
// }

export const activityTableMockProps = {
    appContextDefaults: {
        rootLayoutId: rootLayoutIdMock,

    },
    dataContextDefaults: {
        layout: { [rootLayoutIdMock]: layoutMock(["activityTableModule"]) },
        modules: { allModules: { "activityTableModule": createActivityTableModule({ id: "activityTableModule", name: "ActivityTableModule" }) } }

    },
    mockUser: userMock
}

export const startKitMockProps = {
    appContextDefaults: {
        rootLayoutId: rootLayoutIdMock,

    },
    dataContextDefaults: {
        layout: { [rootLayoutIdMock]: layoutMock(["todoBoardModule", "activityTableModule"]) },
        modules: {
            allModules: {
                // "todoBoardModule": createTodoBoardModule({ id: "todoBoardModule", name: "TodoBoardModule" }),
                "todoBoardModule": TodoBoardModule.createTodoBoardModule({ id: "todoBoardModule", name: "TodoBoardModule" }),
                "activityTableModule": createActivityTableModule({ id: "activityTableModule", name: "ActivityTableModule" }),
            }
        }

    },
    mockUser: userMock
}

export const getScheduledActivity = () => new ScheduledActivity({ id: generateId(), content: "Schedulerad aktivitet", status: ActivityStatus.NOT_STARTED, activityId: "", startTime: undefined })

export const getScheduledActivityCardModule = () => new ScheduledActivityCardModule(
    // baseProps(modules.SCHEDULED_ACTIVITY_CARD, { name: "Card Content" }),
    BaseModule.getDefaultBaseModuleProps("TestKortModul", modules.SCHEDULED_ACTIVITY_CARD),
    {
        containerOnDragStop: (baseModule: BaseModule) => { },
        containerOnResizeStop: (self: BaseModule) => {
            // self.updateFirebase();
        },
        modulePicker: null
    },
    {
        scheduledActivity: getScheduledActivity(),
        onUpdateScheduledActivity: async (updatedScheduledActivity: ScheduledActivityCardModule) => {
            // this.onUpdateScheduledActivity(updatedScheduledActivity);
        },
    },
    {
        parentModule: null,
        childModules: [],
        layout: null,
        newChildModule: async (params?: any) => {return null;},
        onCreateChildModule: (createdModule: BaseModule, yourSelf: BaseModule) => {
            console.log("onCreateChildModule", createdModule, yourSelf)
        },
        onRemoveChildModule: (removedModule?: BaseModule, yourself?: BaseModule) => { }
    }
);
