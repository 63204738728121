import { ScheduledActivityCardModule } from './../module/modules/ScheduledActivityCardModule/ScheduledActivityCardModule';
import { IScheduledActivity, ScheduledActivity } from "../components/activity_generator/ScheduledActivity";
import { IModuleRelationsProps, ModuleRelations } from "../module/modules/ActivityTableModule/ModuleRelations";
import { BaseModule, IBaseModule } from "../module/modules/BaseModule";
import { CalendarEventsModule } from "../module/modules/CalendarEventsModule/CalendarEventsModule";
import { ReactGridModule } from "../module/modules/ReactGridModule/ReactGridModule";
import { TodoBoardModule } from "../module/modules/TodoBoardModule/TodoBoardModule";
import { TAppData } from "./AppContext";
import { TData } from "./interface";
import { generateId } from '../utils/Utils';
import { ICardModuleProps } from '../module/modules/CardModule/CardModule';

export const getRootLayout = async (dataContext: TData, appContext: TAppData) => await dataContext.modules.getModule("LayoutStartPoint", dataContext, appContext, true) as ReactGridModule;
export const getTodoModule = async (dataContext: TData, appContext: TAppData): Promise<TodoBoardModule> => await dataContext.modules.getModule("todoBoardModule", dataContext, appContext, true) as TodoBoardModule;
export const getCalendarEventsModule = async (dataContext: TData, appContext: TAppData): Promise<CalendarEventsModule> => await dataContext.modules.getModule("upcomingEventsModule", dataContext, appContext, true) as CalendarEventsModule;

export const moveScheduledActivityInCalendarEvents = async (scheduledActivityCardModule: ScheduledActivityCardModule, newDate: string, dataContext: TData, appContext: TAppData) => {
  
}

// export const scheduleActivityInCalendarEvents = async (
//   name: string,
//   date: string,
//   dataContext: TData,
//   appContext: TAppData,
//   scheduledActivityCardProps?: {
//     baseModuleProps?: Partial<IBaseModule>,
//     cardModuleProps?: Partial<ICardModuleProps>,
//     relationProps?: Partial<IModuleRelationsProps>,
//     scheduledActivityProps?: Partial<IScheduledActivity>
//   }) => {
//   const calendarEventsModule = await getCalendarEventsModule(dataContext, appContext)
//   const scheduledActivityCardModule: ScheduledActivityCardModule = ScheduledActivityCardModule.createModule(
//     { user: appContext.user, id: generateId(), name, ...scheduledActivityCardProps?.baseModuleProps },
//     { ...scheduledActivityCardProps?.cardModuleProps },
//     { parentModule: calendarEventsModule, ...scheduledActivityCardProps?.relationProps },
//     { ...scheduledActivityCardProps?.scheduledActivityProps },
//   );
//   dataContext.scheduledActivities.setScheduledActivities(scheduledActivityCardModule.scheduledActivity);
//   dataContext.modules.addModule(scheduledActivityCardModule, true);
//   scheduledActivityCardModule.scheduledActivity.updateFirebase(appContext.user);
//   scheduledActivityCardModule.updateFirebase();

//   scheduledActivityCardModule.parentModule = calendarEventsModule;
//   const newCalendarData = await dataContext.calendarData.addScheduleToDate(scheduledActivityCardModule.scheduledActivity, date, dataContext);
//   newCalendarData.updateFirebase(appContext.user);
//   calendarEventsModule.addScheduledActivity(scheduledActivityCardModule, newCalendarData);
//   calendarEventsModule.forceRender();
// }

export const addModuleToRootLayout = async (dataContext: TData, appContext: TAppData, module: ModuleRelations, updateFirebase?: boolean) => {
  const rootLayout = await getRootLayout(dataContext, appContext);
  // rootLayout.addModule(module);
  const newModule = await rootLayout.addChildModule({type: module.type, name: module.name}, rootLayout);
  dataContext.modules.addModule(newModule, true);
  if (newModule && updateFirebase) {
    rootLayout.updateFirebase();
    newModule.updateFirebase();
    rootLayout.forceRender();
  }
}

export const removeModule = (moduleToBeRemoved: ModuleRelations) => {
  moduleToBeRemoved.closeModal();
  if ((moduleToBeRemoved?.parentModule as any)?.showCardContainer) {
    moduleToBeRemoved.parentModule.closeModal();
    (moduleToBeRemoved.parentModule as any).showCardContainer()
  }
  moduleToBeRemoved.parentModule.removeChildModule(moduleToBeRemoved, moduleToBeRemoved.parentModule);
  moduleToBeRemoved.parentModule.forceRender();
}

export const getModuleChildren = async (baseProps: IBaseModule, appContext: TAppData, dataContext: TData): Promise<BaseModule[]> => {
  const props: any = (baseProps as any);
  const nestedIds = props.layout && props.layout[appContext.currentBreakpoint] ? Object.keys(props.layout[appContext.currentBreakpoint]) : [];
  const childModules: BaseModule[] = nestedIds ? await dataContext.modules.getModules(nestedIds, dataContext, appContext, true) : [];
  return childModules
}

export const removeModuleFromRootLayout = async (dataContext: TData, appContext: TAppData, module: ModuleRelations, updateFirebase?: boolean) => {
  const rootLayout = await getRootLayout(dataContext, appContext);
  rootLayout.removeChildModule(module, rootLayout);
  if (updateFirebase) {
    rootLayout.updateFirebase();
  }
}

export const clearObjectAndNestedObjectAndArraysOfUndefines = (obj: any) => {
  if (Array.isArray(obj)) {
    return obj.map(clearObjectAndNestedObjectAndArraysOfUndefines).filter(value => value !== undefined);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const cleanedValue = clearObjectAndNestedObjectAndArraysOfUndefines(obj[key]);
      if (cleanedValue !== undefined) {
        acc[key] = cleanedValue;
      }
      return acc;
    }, {});
  }
  return obj;
}