import { Button } from '../../components/button/Button';
import { Input } from '../../components/input/Input';
import * as React from 'react'
import { useState } from 'react'
import "./InputConfirm.scss"
import classNames from 'classnames';
import { Label } from '../label/Label';
import { Row } from '../layout/row/Row';

interface IProps {
    label?: string;
    labelOnTop?: boolean;
    boldLabel?: boolean;
    defaultValue?: string;
    onConfirm?: Function;
    onChange?: Function;
    buttonLabel?: string
    className?: string
    small?: boolean;
    hideConfirmButton?: boolean
    okButtonTestid?: string
    testId?: string;
}
const InputConfirm = ({ label = "", labelOnTop, boldLabel, onConfirm, defaultValue = "", onChange, buttonLabel = "OK", className, small, hideConfirmButton = false, okButtonTestid = "", testId = "" }: IProps) => {

    const [input, setInput] = useState<string>();

    React.useEffect(() => {
        if (defaultValue) {
            setInput(defaultValue);
        }
    }, [defaultValue])

    const onChangeFunction = value => {
        if (onChange) {
            onChange(value);
        }
        setInput(value)
    }

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            // onConfirm(input);
            setInput("")
            onConfirm(input, e);
        }
    }

    return <div
        date-testid={testId}
        className={classNames(
            `input-confirm${className ? " " + className : ""}`,
            labelOnTop && "label-on-top",
        )}>
        {labelOnTop && <Label
            bold={boldLabel}
            text={label}
        />}
        <Row>
            <Input
                dataTestid={classNames(
                    testId ? `${testId}-input` : ""
                )}
                labelBold={boldLabel}
                label={!labelOnTop && label}
                value={input}
                className={
                    classNames(
                        className
                    )
                }
                onKeyDown={onKeyDown}
                small
                onChange={value => {
                    setInput(value);
                    onChangeFunction(value)
                }} />
            {!hideConfirmButton && <Button
                dataTestid={`${testId}-button`}
                className={classNames(
                    "input-confirm-button",
                    className && `${className}-input-confirm-button`
                )}
                label={buttonLabel}
                onClick={(e) => {
                    setInput("")
                    onConfirm(input, e.target.value)
                }} />}
        </Row>
    </div>
}

export default InputConfirm;