import * as React from 'react'
import { useEffect, useState } from 'react'
import "./Input.scss";
import useDebounce from '../../hooks/useDebounce';
import classNames from 'classnames';

interface IInput {
    label?: string;
    labelBold?: boolean;
    value?: string;
    onChange: Function;
    onKeyDown?: (e: any) => void;
    onClick?: (e: any) => void
    className?: string;
    disabled?: boolean
    dataTestid?: string;
    small?: boolean
    debounce?: number;
    placeholder?: string;
}
const Input = ({ label, labelBold, value = "", onChange, onKeyDown = () => { }, className = "", disabled = false, dataTestid, small, debounce: debounce = 0, placeholder = undefined, onClick = undefined }: IInput) => {
    const [val, setValue] = useState("")

    // useEffect(() => {
    //     setValue(value);
    // }, [value])

    useDebounce(() => {
        onChange(val);
    }, [val], debounce
    );
    
    const handleKeyDown = (e: any) => {
        setValue(e.target.value);
        onKeyDown(e);
        onChange(e.target.value);
    }

    return (
        <div
            onClick={onClick}
            className={`${className}`}
        >
            <label /* placeholder={placeholder} */>
                {label && labelBold ? <b><span className="margin-right">{label}</span></b> : <span className="margin-right">{label}</span>}
                <input placeholder={placeholder} className={classNames(
                    `input${small ? " small" : ""}`,
                    className && `${className}-input`
                )} type="text" value={val} onChange={(e) => {
                    setValue(e.target.value);
                }}
                    onKeyDown={(e) => {handleKeyDown(e)}}
                    disabled={disabled}
                    data-testid={dataTestid}
                ></input>
            </label>
        </div>
    )
}

export { Input }