import * as React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { IsolatedEnvironment } from './app/IsolatedEnvironment';
import { activitiesMock, activityTableMockProps, createActivityTableModule, getScheduledActivityCardModule, reactGridLayoutMockNoModules } from './data/mock/MockData';
import { AppInitiator } from './app/AppInitiator';
import { CardModule } from './module/modules/CardModule/CardModule';
import { modules } from './utils/constants';
import { IStartkit, Signup } from './login/Signup';
import { BaseModule } from './module/modules/BaseModule';
import { TodoBoardModule } from './module/modules/TodoBoardModule/TodoBoardModule';
import { Activity, getDefaultActivity } from './data/activity/Activity';
import { ScheduledActivityCardModule } from './module/modules/ScheduledActivityCardModule/ScheduledActivityCardModule';
import { on } from 'events';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <IsolatedEnvironment testModules={[TodoBoardModule.createTodoBoardModule(undefined, { onCreateChildModule: () => {} })]} />
  <AppInitiator />
//   <IsolatedEnvironment testModules={[
//     ScheduledActivityCardModule.createModule({name: "ScheduledActivityCardModule"})
// ]} />
  // <IsolatedEnvironment testModules={[TodoBoardModule.createTodoBoardModule()]} />
  // <IsolatedEnvironment testModules={
  //   [
  //       CardModule.createModule(
  //           { name: "Card1" },
  //           undefined,
  //           {
  //             onCreateChildModule: (created, parent) => {console.log("created", created.name, parent.name)},
  //             onRemoveChildModule: (removed, parent) => {console.log("removed", removed.name, parent.name)}
  //           }
  //       )
  //   ]} />
  // <IsolatedEnvironment testModules={
  //   [
  //       CardModule.createCardModule(
  //           { name: "Card1" },
  //           { onCreateChildModule: (arg1, arg2) => {
  //             console.log(arg1.name, arg2.name)
  //           }, onRemoveChildModule: () => {} }
  //       )
  //   ]} />
  // <IsolatedEnvironment testModules={[
  //   getScheduledActivityCardModule(),
  //   CardModule.createCardModule(BaseModule.getDefaultBaseModuleProps("TestKort", modules.CARD, { user: "testuser" }))
  // ]} />
  // <IsolatedEnvironment testModules={[
  //   CardModule.createCardModule(BaseModule.getDefaultBaseModuleProps("TestKort", modules.CARD, { user: "testuser" }))
  // ]}
  // />
  // <IsolatedEnvironment testModule={TodoBoardModule.createTodoBoardModule(undefined, undefined, undefined, [getDefaultActivity("test")])} />
  // <IsolatedEnvironment
  //   testButton
  //   testModule={reactGridLayoutStandardModules()}
  // />
  // <IsolatedEnvironment testModule={createCardModuleWithChild()} />
  // <AppInitiator
  //   width="100%"
  //   // testButton
  //   runInIsolation
  //   testModule={reactGridLayoutStandardModules()}
  // />
  // <Signup defaultName="TestUsername" defaultEmail="test@test.test" defaultPassword='test123' onCreateUser={function (startkit: IStartkit): void {
  //   console.log(startkit);
  // } } />
  // <IsolatedEnvironment testModule={TodoBoardModule.createTodoBoardModule()} />
  // <ModuleCreator onCreate={newModule => console.log(newModule)}/>
  // <IsolatedEnvironment testModule={getCalendarEventsModule(undefined, {activities: activitiesMock})} />
  // <IsolatedEnvironment testModule={createActivityTableModule()} />
  // <IsolatedEnvironment testModule={getCalendarEventsModule(undefined, {activities: activitiesMock})} />
  // <IsolatedEnvironment testModule={getCalendarEventsModule()} />
  // <IsolatedEnvironment testModule={getScheduledActivityCardModule()} />
  // <Row>
  // <IsolatedEnvironment testModule={new CardModule(
  //   baseProps(modules.CARD, { name: "Card1" }),
  //   undefined,
  //   {
  //     parentModule: null, childModules: [], layout: null, onCreateChildModule: (createdModule: BaseModule, yourSelf: BaseModule) => {
  //       console.log("onCreateChildModule", createdModule, yourSelf)
  //     }
  //   }
  // )} />
  // <IsolatedEnvironment testModule={CardModule.createNewChildModule("Card1", null, "")} />
  //   <IsolatedEnvironment testModule={createBoardModule(undefined, {
  //     layout: {
  //       [breakpointTypes.LG]: { ["testid"]: {i: "testid", y: 0, w: 4, h: 20, x: 0 } }
  //     },
  //     modules: [new CardModule({ id: "testid", name, type: modules.CARD, user: "testuser", parentLayoutId: "" })]
  //   })
  //   } />
  // </Row>

  //rootModule.elements = rootModule.layoutToRenderedElements(rootModule.layout?.getAllGrids(breakpointTypes.LG), modules);
  // <AppInitiator 
  //   runInIsolation
  //   testModule={getCalendarEventsModule()}
  // />
  // <AppInitiator
  //   appContextDefaults={
  //     { user: "testuserid" }
  //   }
  // />
  // <AppInitiator {...appMockProps}/>
  // <AppInitiator
  //   {...activityTableMockProps}
  //   runInIsolation={true}
  //   testModule={createTodoBoardModule()}
  // />
  // <AppInitiator runInIsolation testModule={createBoardModule()}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

