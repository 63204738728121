import * as React from 'react';
import { BaseModule, IBaseModule } from "../BaseModule";
import { Activity } from '../../../data/activity/Activity';
import { getDuration, getNow } from '../../../utils/TimeUtils';
import { Button } from '../../../components/button/Button';
import { ScheduledActivity } from '../../../components/activity_generator/ScheduledActivity';
import { IRow, ICell, Table } from '../../../components/table/Table';
import { Row } from '../../../components/layout/row/Row';
import { PlusIcon } from '../../../icons/PlusIcon';
import { QuickActivityCreator } from '../../../components/quick_activity_creator/QuickActivityCreator';
import { ActivityStatus, breakpointTypes, getModulesPath, GridHeightsLG, GridHeightsSM, GridWidthsLG, GridWidthsSM, ILayouts } from '../../../utils/constants';
import { TData } from '../../../app/interface';
import { writeData } from '../../../utils/Firebase';
import ScheduledActivityViewer from '../ScheduledActivityCardModule/ScheduledActivityViewer';
import { ActivityViewer } from '../../../components/ActivityViewer/ActivityViewer';
import { GridItem } from '../ReactGridModule/GridItem';

// type TRowsData = { activity: Activity}[];

export interface IActivityTableModuleProps {
    onCreateActivity: (activity: Activity) => Promise<void>;
    onDeleteActivity: (activity: Activity) => void;
    onUpdateActivity: (activity: Activity) => void;
    onCreateScheduledActivity: (activity: ScheduledActivity) => void;
    onFinishScheduledActivity: (scheduledActivity: ScheduledActivity) => void;
    onDeleteScheduledActivity: (scheduledActivity: ScheduledActivity) => void;
    sendToTodoList: (activity: Activity) => void;
    // rowData?: TRowsData;
    activities: Activity[];
    scheduledActivities?: { [key: string]: ScheduledActivity };
    startDate: string;
    endDate: string;
}

export class ActivityTableModule extends BaseModule {
    onSubscribe: Function;
    onCreateActivity;
    onDeleteActivity;
    onUpdateActivity;
    onFinishScheduledActivity;
    onDeleteScheduledActivity;
    onCreateScheduledActivity;
    sendToTodoList;
    // rowData: TRowsData;
    startDate: string;
    endDate: string;
    activities: Activity[];
    scheduledActivities?: { [key: string]: ScheduledActivity };
    dataContextArgs?: string[];

    constructor(
        baseProps: IBaseModule,
        activityTableModuleProps: IActivityTableModuleProps
    ) {
        super(baseProps);
        // console.log(activityTableModuleProps.onCreateScheduledActivity);
        Object.assign(this, activityTableModuleProps);
        // this.activities = activityTableModuleProps.activities || [];
        // this.scheduledActivities = activityTableModuleProps.scheduledActivities || {};
        this.autoPackingDimensions = { w: 24, h: 30 };
        this.dataTestid = 'activity-table-module';
        this.dataContextArgs = ["activities.activities", "calendarData.calendarData", "scheduledActivities.scheduledActivities"]
    }

    onDataContextUpdate = async (dataContext: TData) => {
        // this.scheduledActivities = dataContext.scheduledActivities.scheduledActivities;
        // if (dataContext?.activities?.activities?.length) {
        //     this.activities = dataContext.activities.activities;
        // }
        // console.log(dataContext.activities.activities);
        // //@ts-ignore
        // console.log(Object.values(dataContext.scheduledActivities.scheduledActivities).filter(s => s.content.includes("köksbordet")));
        // this.forceRender();
    }

    actionMenu?(appContext: any, dataContext: TData): JSX.Element[] {
        return null;
    }

    updateModuleFirebase() {
        const firebaseData: Omit<IBaseModule, "onDelete"> = { id: this.id, user: this.user, type: this.type, name: this.name }
        writeData(getModulesPath(this.user, this.id), firebaseData);
    }

    getCreationDialog(): JSX.Element {
        return <div>creation dialog</div>
    }

    renderTopMenu(): JSX.Element {
        return <Row>
            <div
                className="center-content"
                data-testid="activity-table-create-activity-button"
                role="button"
                onClick={() => {
                    this.toggleModal(
                        <QuickActivityCreator
                            testId={'activity-creator'}
                            onCreate={async (activity: Activity) => {
                                this.activities.push(activity)
                                this.onCreateActivity(activity);
                                this.closeModal()
                            }} />
                    )
                }}>
                Skapa ny aktivitet
                <PlusIcon />
            </div>
        </Row >
    }
    customDelete = (dataContext: TData): void => {
        dataContext.modules.deleteModule(this);
    }

    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.HALF,
                h: GridHeightsSM.FULL,
            }, this.id),
            [breakpointTypes.LG]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsLG.HALF,
                h: GridHeightsLG.FULL,
            }, this.id)
        }
    }

    deleteScheduledActivity = (scheduledActivity: ScheduledActivity) => {
        // this.scheduledActivities = this.scheduledActivities.filter(s => s.id !== scheduledActivity.id);
        delete this.scheduledActivities[scheduledActivity.id];
        this.onDeleteScheduledActivity(scheduledActivity);
        this.forceRender();
    }

    setRows = (rowsData: Activity[]): IRow[] => {

        const getRowData = (scheduledActivities: ScheduledActivity[]): {
            lastCompleted: ScheduledActivity,
            oldestOpen: ScheduledActivity,
            timeLeftToStart: number
        } => {
            scheduledActivities.sort((a, b) => a.startTime < b.startTime ? 1 : -1);
            const allCompleted: ScheduledActivity[] = scheduledActivities.filter(s => s.status === ActivityStatus.DONE);
            const lastCompleted = allCompleted[0];
            const allOpen = scheduledActivities.filter(s => s.status !== ActivityStatus.DONE).sort((a, b) => a.startTime > b.startTime ? 1 : -1);
            const oldestOpen: ScheduledActivity = allOpen[0];
            const timeLeftToStart = oldestOpen?.startTime ? oldestOpen.startTime - getNow() : 0
            // console.log(oldestOpen?.content, oldestOpen?.getStartTimeDate());
            return { lastCompleted, oldestOpen, timeLeftToStart };
        }

        const getTimeSinceInterval = (scheduledActivity: ScheduledActivity): number => {
            const test = scheduledActivity?.stopTime > 0 && scheduledActivity?.interval > 0 ? scheduledActivity.stopTime + scheduledActivity.interval : ""
            if(test){
                const newTest = test - getNow()
                return newTest;
            }
            else {return 0}
        }

        const getRows = (rowsData: Activity[]): IRow[] => {

            const rows: IRow[] = rowsData.map((activity: Activity) => {
                const scheduledActivities = Object.values(this.scheduledActivities).filter(s => s.activityId === activity.id);
                // if (activity.name === "Tidrapportera") {
                //     console.log(scheduledActivities.sort((a, b) => a.startTime - b.startTime).map(s => ({ ...s, startTime: new Date(s.startTime) })));
                // }
                const { lastCompleted, timeLeftToStart, oldestOpen } = getRowData(scheduledActivities);

                const row = {
                    id: activity.id,
                    cells: [
                        {
                            columnId: "name", content: <div data-testid="cell-content" onClick={() => {
                                this.toggleModal(
                                    <ActivityViewer
                                        latestScheduledActivities={scheduledActivities.filter(s => s.activityId === activity.id) || []}
                                        activity={activity}
                                        sendToTodoList={this.sendToTodoList}
                                        onUpdateActivity={this.onUpdateActivity}
                                        onDeleteActivity={(activity: Activity) => {
                                            // this.scheduledActivities = this.scheduledActivities.filter(row => row.activity.id !== activity.id);
                                            this.onDeleteActivity(activity);
                                            this.closeModal();
                                        }}
                                        onCreateScheduledActivity={this.onCreateScheduledActivity}
                                        onSendToTodoList={() => {
                                            this.closeModal();
                                        }}
                                    />
                                )
                            }}>{activity.name || "Error"}</div>
                        },
                        { columnId: "interval", content: activity.interval ? getDuration(activity.interval) : "" },
                        { columnId: "lastCompleted", content: lastCompleted?.stopTime ? getDuration(getNow() - lastCompleted.stopTime) : "" },
                        { columnId: "dueTimeInterval", content: getTimeSinceInterval(lastCompleted), extra: {timeSinceInterval: getTimeSinceInterval(lastCompleted)} },
                        { columnId: "dueTime", content: timeLeftToStart, extra: { timeLeftToStart, oldestOpen } },
                        {
                            columnId: "complete", content: oldestOpen ? <Button
                                size="small"
                                dataTestid="complete-last"
                                onClick={() => {
                                    oldestOpen.finish();
                                    this.scheduledActivities[oldestOpen.id] = oldestOpen;
                                    this.onFinishScheduledActivity(oldestOpen)
                                    this.forceRender();
                                }}
                                label={'OK'}
                            /> : ""
                        },
                        {
                            columnId: "removeScheduled",
                            content: timeLeftToStart ? <Button label="OK"
                                dataTestid="remove-last"
                                size="small"
                                onClick={() => {
                                    delete this.scheduledActivities[oldestOpen.id];
                                    this.deleteScheduledActivity(oldestOpen);
                                    this.forceRender();
                                }} /> : null
                        },
                    ],
                    rowStyling: { color: 'black' }
                }
                return row;
            })
            return rows;

        }
        const rws: IRow[] = getRows(rowsData);
        return rws;
    }

    renderTable = (rows: IRow[]) => <Table
        testId='activity-table'
        className="activity-table"
        customSort={{ colId: "dueTime", asc: false }}
        // mobileCells={["schedule", "name", "dueTime"]}
        settingsId={''}
        columns={[
            // { id: "status", label: "Status" },
            { id: "name", label: "Namn" },
            { id: "interval", label: "Intervall" },
            { id: "lastCompleted", label: "Senast slutförd" },
            { id: "dueTimeInterval", label: "Tid kvar intervall", 
                formatter: (row: IRow, cell: ICell, extra?: any) => {
                    return <div style={{ color: extra?.timeSinceInterval < 0 ? "red" : "green" }}>{extra?.timeSinceInterval ? getDuration(extra.timeSinceInterval) : ""}</div>;
                },
             },
            {
                id: "dueTime", label: "Tid kvar",
                formatter: (row: IRow, cell: ICell, extra?: any) => {
                    return <div style={{ color: extra?.timeLeftToStart < 0 ? "red" : "green" }}>{extra?.timeLeftToStart ? getDuration(extra.timeLeftToStart) : ""}</div>;
                },
                onClickCellModal: async (row, cell, extra) => {
                    if (extra) {
                        // return <ScheduledActivityViewer
                        //     onStartTimeUpdate={(...props) => {
                        //         console.log(props)
                        //     }}
                        //     dataTestid="activity-table-module"
                        //     scheduledActivity={extra.oldes}
                        //     onDelete={this.onDeleteScheduledActivity}
                        //     onSendToTodoList={undefined}
                        // />
                        return <ScheduledActivityViewer
                            scheduledActivity={extra.oldes}
                            dataTestid='activity-table-module'
                            onDeleteScheduledActivity={() => {
                                this.onDeleteScheduledActivity()
                            }}
                            onUpdateScheduledActivity={this.onFinishScheduledActivity}
                        />
                    }
                    return <div>hej</div>
                },
            },
            // { id: "timeLeftInterval", label: "Tid kvar till intervall" },
            { id: "complete", label: "Klar" },
            { id: "removeScheduled", label: "Ta bort senast schedulerade" },
        ]}
        rows={rows} />

    render(): JSX.Element {
        const rows = this.setRows(this.activities) || [];
        return this.renderTable(rows)
    }

    toFirebaseObject() {

    }

}