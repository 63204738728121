//@ts-nocheck
import * as React from 'react'
import { AppContext } from '../app/AppContext';
import { Box } from "../components/box/Box"
import { useSettings } from '../hooks/useSettings';

interface IBoxedModule {
    moduleName: string;
    onDelete: () => void;
    hideHeader?: boolean;
    hideMinimizer?: boolean;
    onMinimize?: (isMinimized: boolean) => void;
    dragHandle: string;
    actionMenu?: JSX.Element[];
    topMenu?: JSX.Element
    content?: JSX.Element;
    footer?: (dataContext: TData) => JSX.Element;
    showModal?: boolean;
    // lastChecked?: string;
    onHeaderNameUpdate: (name: string) => void;
    testId?: string;
    module: BaseModule
}

const BoxedModule = ({ dragHandle, actionMenu, moduleName, onDelete, hideHeader = false, hideMinimizer = false, onMinimize, topMenu, content, footer, onHeaderNameUpdate, lastChecked, testId, module }: IBoxedModule) => {
    const { currentBreakpoint, user } = React.useContext(AppContext);
    const { getSetting } = useSettings(user);

    const showIds = getSetting("showModuleIds");

    return (
        <Box
            module={module}
            dragHandle={dragHandle}
            hideMinimizer={hideMinimizer}
            headerText={moduleName /* + (showIds ? "/" + sitemapModule.id.split("-")[0] || sitemapModule.type : "") + " " + (lastChecked || "") */}
            hideHeader={hideHeader}
            // className={sitemapModule.type}
            // sitemapPath={sitemapModule?.sitemapPath || ""}
            onHeaderEditConfirm={onHeaderNameUpdate}
            // deletePaths={[sitemapModule.sitemapPath, sitemapModule.modulePath]}
            closeOnConfirm
            onDelete={onDelete}
            // minimized={sitemapModule?.grid ? sitemapModule?.grid[currentBreakpoint]?.minimised : false}
            // onMinimize={onMinimize}
            hideMenu={false}
            actionMenu={actionMenu}
            // sitemapModule={sitemapModule}
            footer={footer}
            testId={testId}
        >
            {topMenu}
            {content}
        </Box>
    )
}


export { BoxedModule }