import * as React from 'react'
import { Rule } from '../../data/activity/Rule/Rule';
import { generateId } from '../../utils/Utils';
import { ActivityRulesSelector } from './ActivityRulesSelector';
import { ActivityRulesCreator } from './ActivityRulesCreator';
import { Button } from '../button/Button';
import { Column } from '../layout/column/Column';
import { MarginBottom } from '../layout/margin/MarginBottom';
import Modal from '../modal/Modal';
import { Activity } from '../../data/activity/Activity';
import { DurationPicker } from '../pickers/duration_picker/DurationPicker';

const ActivityRulesViewer = ({ activity, onIntervalChange, onUpdateActivity }: {
    activity: Activity,
    onIntervalChange: (interval: number) => void,
    // rules: { id: string, name: string, rules: Rule[] }[],
    onUpdateActivity: (activity: Activity) => void
}) => {
    const [modalType, setModalType] = React.useState("");
    const [selectedRuleset, setSelectedRuleset] = React.useState<{ id: string, name: string, ruleSet: Rule[] } | null>(null);

    const renderInterval = () => <Column alignLeft>
        <b>Intervall</b>
        <DurationPicker
            defaultValue={activity.interval || 0}
            onChange={(millis) => {
                onIntervalChange(millis)
            }}
        />
    </Column>

    

    return (
        <Column alignLeft>
            <h3>Automatisera</h3>
            <h4>{activity.id}</h4>
            <ActivityRulesSelector
                activity={activity}
                onSelect={(id) => {
                    setSelectedRuleset(activity.rules.find(rule => rule.id === id));
                    setModalType("create");
                }}
                onRemove={(ruleId: string) => {
                    activity.removeRule(ruleId);
                    onUpdateActivity(activity);
                }}
                 />
            <MarginBottom />
            <Button label="Sätt intervall" onClick={() => {
                setSelectedRuleset({ id: generateId(), name: "", ruleSet: [] });
                // setSelectedRuleset({ id: generateId(), rules: [] });
                setModalType("interval");
            }}
            />
            {/* <MarginBottom />
             <Button label="Sätt starttid" onClick={() => {
                setSelectedRuleset({ id: generateId(), name: "", rules: [] });
                // setSelectedRuleset({ id: generateId(), rules: [] });
                setModalType("starttime");
            }}
            /> */}
            <MarginBottom />
            <Button label="Lägg till en ny regel" onClick={() => {
                setSelectedRuleset({ id: generateId(), name: "", ruleSet: [] });
                // setSelectedruleSetet({ id: generateId(), rules: [] });
                setModalType("create");
            }}
            />
            <MarginBottom />
            <Button label="Spara" onClick={() => {
                setSelectedRuleset({ id: generateId(), name: "", ruleSet: [] });
                // setSelectedRuleset({ id: generateId(), rules: [] });
                // setModalType("create");
                onUpdateActivity(activity);
                setModalType("");
            }} />
            {/* <ActivityRulesCreator selectedRule={rules?.length > 0 ? rules[0] : { id: generateId(), rules: [] }} /> */}
            <Modal showModal={!!modalType} setShowModal={setModalType}>
                {modalType === "create" && <ActivityRulesCreator
                    // activity={activity}
                    // onIntervalChange={onIntervalChange}
                    selectedRule={selectedRuleset}
                    onCreate={(newRule: { id: string, name: string, ruleSet: Rule[] }) => {
                        if(!activity.rules){
                            activity.rules = []
                        }
                        const foundRule = activity.rules.find(rule => rule.id === newRule.id);
                        if (foundRule) {
                            activity.rules[activity.rules.findIndex(rule => rule.id === newRule.id)] = newRule;
                        }
                        else {
                            activity.rules.push(newRule);
                        }
                        setModalType("");
                    }}
                />}
                {modalType === "interval" && renderInterval()}
            </Modal>
        </Column>
    )
}

export { ActivityRulesViewer }